import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import './AdventurerDashboard.css';

// Add import for the spell data files
import spellsIndex from '../data/spells/index.json';

const AdventurerDashboard = ({ adminMessages }) => {
  const { currentUser, logout } = useAuth();
  const [activeTab, setActiveTab] = useState('overview');
  const [avatarUrl, setAvatarUrl] = useState(currentUser?.avatar || '');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [characterSheet, setCharacterSheet] = useState(null);
  const [isLoadingCharacter, setIsLoadingCharacter] = useState(false);
  const [characterError, setCharacterError] = useState('');
  const [isMounted, setIsMounted] = useState(true);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryFolders, setGalleryFolders] = useState([]);
  const [galleryCategories, setGalleryCategories] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [isLoadingGallery, setIsLoadingGallery] = useState(false);
  const [galleryError, setGalleryError] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentGalleryFolder, setCurrentGalleryFolder] = useState(null);
  const [currentGalleryCategory, setCurrentGalleryCategory] = useState(null);
  const [folderImages, setFolderImages] = useState([]);
  const [characterTabActive, setCharacterTabActive] = useState('details');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [characterSheetJson, setCharacterSheetJson] = useState(null);
  const [activeCharacterTab, setActiveCharacterTab] = useState('summary');
  const characterSheetRef = useRef(null);
  const [passwordData, setPasswordData] = useState({ current: '', new: '', confirm: '' });
  const [localAdminMessages, setLocalAdminMessages] = useState(adminMessages || []);
  const [avatar, setAvatar] = useState(null);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const [avatarError, setAvatarError] = useState('');
  const [loadingCharacterSheet, setLoadingCharacterSheet] = useState(false);
  const [characterSheetError, setCharacterSheetError] = useState('');
  const [showUploadForm, setShowUploadForm] = useState(false);
  // Add state to track minimized messages
  const [minimizedMessages, setMinimizedMessages] = useState([]);
  // Add state to track if character PDF exists
  const [characterPdfUrl, setCharacterPdfUrl] = useState('');
  // Add these state variables after the other state declarations
  const [spellLibrary, setSpellLibrary] = useState([]);
  const [filteredSpells, setFilteredSpells] = useState([]);
  const [activeSpellFilters, setActiveSpellFilters] = useState({
    level: [],
    school: [],
    prepared: false,
    favorites: false
  });
  const [spellSlots, setSpellSlots] = useState({});
  const [spellSearch, setSpellSearch] = useState('');
  const [viewMode, setViewMode] = useState('library'); // 'library', 'slots', 'abilities', 'spellbook'
  const [selectedSpell, setSelectedSpell] = useState(null);
  const [abilityResources, setAbilityResources] = useState([]);
  const [customSpellCategories, setCustomSpellCategories] = useState([]);
  const [showSpellDetails, setShowSpellDetails] = useState(false);
  const [favoriteSpells, setFavoriteSpells] = useState([]);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [activeClassFilter, setActiveClassFilter] = useState('All'); // Move this state to component level
  const [preparedSpells, setPreparedSpells] = useState([]);
  const [spellFilterValue, setSpellFilterValue] = useState('');
  const [spellCasterClass, setSpellCasterClass] = useState('wizard');
  const [characterLevel, setCharacterLevel] = useState(1);
  
  // Add these state variables after the existing spell state declarations
  const [allSpellsData, setAllSpellsData] = useState([]);
  const [isLoadingSpells, setIsLoadingSpells] = useState(false);
  const [spellSourceBooks, setSpellSourceBooks] = useState([]);
  const [activeSpellSourceFilters, setActiveSpellSourceFilters] = useState([]);
  
  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';  // Replace with your actual production API URL
  
  // Character sheet parsing utilities - improves data access
  const getNestedValue = (obj, path, defaultValue = 'Unknown') => {
    if (!obj) return defaultValue;
    
    // Handle both string paths ('system.abilities.str.value') and array paths (['system', 'abilities', 'str', 'value'])
    const parts = Array.isArray(path) ? path : path.split('.');
    let current = obj;
    
    for (const part of parts) {
      if (current === null || current === undefined || typeof current !== 'object') {
        return defaultValue;
      }
      current = current[part];
    }
    
    return current !== null && current !== undefined ? current : defaultValue;
  };
  
  // Parse the character sheet to get a more structured access pattern
  // This maintains compatibility with existing code but provides better alternatives
  const parseCharacterSheet = (rawData) => {
    if (!rawData) return null;
    
    const parsed = {
      raw: rawData, // Keep reference to raw data
      
      // Basic info
      name: rawData.name || 'Unnamed Character',
      level: getNestedValue(rawData, 'system.details.level', 
              getNestedValue(rawData, 'level', 0)),
              
      // Basic attributes grouped for easier access
      attributes: {
        hp: {
          value: getNestedValue(rawData, 'system.attributes.hp.value', 0),
          max: getNestedValue(rawData, 'system.attributes.hp.max', 0),
          temp: getNestedValue(rawData, 'system.attributes.hp.temp', 0)
        },
        ac: getNestedValue(rawData, 'system.attributes.ac.value', 10),
        initiative: getNestedValue(rawData, 'system.attributes.init.total', 0),
        speed: getNestedValue(rawData, 'system.attributes.movement.walk', 30),
        profBonus: getNestedValue(rawData, 'system.attributes.prof.value', 2)
      },
      
      // Movement types for easy access
      movement: {},
      
      // Abilities scores and modifiers
      abilities: {},
      
      // Grouped items by type for easier lookup
      itemsByType: {},
      
      // Key character details
      details: {
        race: null,
        class: null,
        background: null,
        alignment: getNestedValue(rawData, 'system.details.alignment', 'Unknown')
      },
      
      // Character currency
      currency: {
        pp: getNestedValue(rawData, 'system.currency.pp', 0),
        gp: getNestedValue(rawData, 'system.currency.gp', 0),
        ep: getNestedValue(rawData, 'system.currency.ep', 0),
        sp: getNestedValue(rawData, 'system.currency.sp', 0),
        cp: getNestedValue(rawData, 'system.currency.cp', 0)
      },
      
      // Biography data
      biography: {}
    };
    
    // Process movement types from the character sheet
    const movement = getNestedValue(rawData, 'system.attributes.movement', {});
    if (typeof movement === 'object') {
      Object.entries(movement).forEach(([key, value]) => {
        parsed.movement[key] = value;
      });
    }
    
    // Process ability scores
    const abilities = getNestedValue(rawData, 'system.abilities', {});
    if (typeof abilities === 'object') {
      Object.entries(abilities).forEach(([key, ability]) => {
        const value = ability?.value || 10;
        const mod = Math.floor((value - 10) / 2);
        parsed.abilities[key] = {
          value: value,
          mod: mod,
          proficient: ability?.proficient || false
        };
      });
    }
    
    // Group items by type for easier lookup
    if (Array.isArray(rawData.items)) {
      rawData.items.forEach(item => {
        const type = item.type || 'unknown';
        if (!parsed.itemsByType[type]) {
          parsed.itemsByType[type] = [];
        }
        parsed.itemsByType[type].push(item);
      });
      
      // Find character class (prioritize class item over system data)
      const classItems = rawData.items.filter(item => 
        item.type === 'class' && item.name && item.name.trim() !== ''
      );
      if (classItems.length > 0) {
        // Join multiple class names if there are several
        parsed.details.class = classItems.map(item => item.name).join(' / ');
      } else {
        parsed.details.class = getNestedValue(rawData, 'system.details.class', 
                             getNestedValue(rawData, 'system.details.originalClass', 'Unknown'));
      }
      
      // Find character race (prioritize race item over system data)
      const raceItem = rawData.items.find(item => 
        item.type === 'race' || item.type === 'ancestry' || item.type === 'heritage'
      );
      if (raceItem && raceItem.name) {
        parsed.details.race = raceItem.name;
      } else {
        parsed.details.race = getNestedValue(rawData, 'system.details.race', 
                            getNestedValue(rawData, 'system.traits.race', 'Unknown'));
      }
      
      // Find character background
      const bgItem = rawData.items.find(item => item.type === 'background');
      if (bgItem && bgItem.name) {
        parsed.details.background = bgItem.name;
      } else {
        parsed.details.background = getNestedValue(rawData, 'system.details.background', 'Unknown');
      }
    }
    
    // Process biography data from multiple potential locations
    let bio = null;
    if (rawData.system?.details?.biography) {
      bio = rawData.system.details.biography;
    } else if (rawData.system?.description) {
      bio = rawData.system.description;
    } else if (rawData.system?.details?.description) {
      bio = rawData.system.details.description;
    } else if (rawData.description) {
      bio = rawData.description;
    }
    
    if (bio) {
      // Properly format biography fields
      parsed.biography = {
        full: bio.value || bio.text || bio.public || '',
        appearance: bio.appearance || bio.physical || '',
        traits: bio.trait || bio.traits || bio.personality || '',
        ideals: bio.ideal || bio.ideals || '',
        bonds: bio.bond || bio.bonds || '',
        flaws: bio.flaw || bio.flaws || '',
        backstory: bio.backstory || bio.background || ''
      };
    }
    
    return parsed;
  };
  
  // Store the parsed character sheet data
  const [parsedCharacter, setParsedCharacter] = useState(null);
  
  // Update parsed character whenever characterSheet changes
  useEffect(() => {
    if (characterSheet) {
      setParsedCharacter(parseCharacterSheet(characterSheet));
    } else {
      setParsedCharacter(null);
    }
  }, [characterSheet]);
  
  // Fetch the user's avatar and character sheet on component mount
  useEffect(() => {
    fetchAvatar();
    fetchCharacterSheet();
    fetchAdminMessages();
  }, []);

  // Fetch avatar when component mounts
  useEffect(() => {
    setIsMounted(true);
    if (isMounted) {
      fetchAvatar();
      // If user is adventurer, fetch character sheet
      if (currentUser?.role === 'adventurer') {
        fetchCharacterSheet();
      }
    }

    return () => {
      setIsMounted(false);
    };
  }, [isMounted, currentUser]);

  // Fetch gallery categories when gallery tab is selected
  useEffect(() => {
    if (activeTab === 'gallery' && galleryCategories.length === 0 && !isLoadingGallery) {
      fetchGalleryCategories();
    }
  }, [activeTab, galleryCategories.length, isLoadingGallery]);

  // Add this effect to initialize gallery data when component mounts
  useEffect(() => {
    if (isMounted && activeTab === 'gallery') {
      fetchGalleryCategories();
    }
  }, [activeTab, isMounted]);

  // Make sure this effect runs when switching from folder to categories view
  useEffect(() => {
    if (currentGalleryFolder === null && currentGalleryCategory !== null) {
      setCurrentGalleryCategory(null);
      setGalleryImages([]);
      if (activeTab === 'gallery') {
        fetchGalleryCategories();
      }
    }
  }, [currentGalleryFolder, currentGalleryCategory, activeTab]);

  // Fetch user's avatar from the server
  const fetchAvatar = async () => {
    try {
      if (!currentUser || !isMounted) return;
      
      const response = await fetch(`${API_URL}/avatar`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok && isMounted) {
        const imageBlob = await response.blob();
        const imageUrl = URL.createObjectURL(imageBlob);
        setAvatarUrl(imageUrl);
      } else {
        console.error('Failed to fetch avatar');
      }
    } catch (error) {
      console.error('Error fetching avatar:', error);
    }
  };

  // Fetch user's character sheet
  const fetchCharacterSheet = async () => {
    try {
      if (!isMounted) return;
      
      setIsLoadingCharacter(true);
      setCharacterError('');
      // Clear the current character sheet data before fetching new data
      setCharacterSheet(null);
      
      const response = await fetch(`${API_URL}/character-sheet`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to fetch character sheet');
      }
      
      const data = await response.json();
      if (isMounted) {
        setCharacterSheet(data);
        
        // Check if the character PDF exists with proper error handling
        try {
          console.log("Checking for character PDF...");
          const pdfCheckResponse = await fetch(`${API_URL}/character-pdf`, {
            method: 'HEAD', // This is the issue - use GET instead of HEAD for some servers
            headers: {
              'x-auth-token': localStorage.getItem('auth_token')
            }
          });
          
          console.log("PDF check response status:", pdfCheckResponse.status);
          
          if (pdfCheckResponse.ok) {
            console.log("PDF found, setting URL");
            setCharacterPdfUrl(`${API_URL}/character-pdf?t=${Date.now()}`);
          } else {
            // Try a regular GET request if HEAD fails
            console.log("HEAD request failed, trying GET...");
            const pdfGetResponse = await fetch(`${API_URL}/character-pdf`, {
              method: 'GET',
              headers: {
                'x-auth-token': localStorage.getItem('auth_token')
              }
            });
            
            if (pdfGetResponse.ok) {
              console.log("PDF found via GET, setting URL");
              setCharacterPdfUrl(`${API_URL}/character-pdf?t=${Date.now()}`);
            } else {
              console.log("PDF not found via GET either");
              setCharacterPdfUrl('');
            }
          }
        } catch (pdfErr) {
          console.error('Error checking for character PDF:', pdfErr);
          setCharacterPdfUrl('');
        }
      }
    } catch (err) {
      if (isMounted) {
        console.error('Error fetching character sheet:', err);
        setCharacterError(err.message || 'An error occurred while fetching character sheet');
      }
    } finally {
      if (isMounted) {
        setIsLoadingCharacter(false);
      }
    }
  };

  // Fetch gallery categories
  const fetchGalleryCategories = async () => {
    try {
      setIsLoadingGallery(true);
      setGalleryError('');
      
      const response = await fetch(`${API_URL}/gallery/categories`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setGalleryCategories(data.categories);
      } else {
        setGalleryError('Failed to load gallery categories');
      }
    } catch (error) {
      console.error('Error fetching gallery categories:', error);
      setGalleryError('An error occurred while loading the gallery');
    } finally {
      setIsLoadingGallery(false);
    }
  };

  // Fetch images from a specific folder
  const fetchFolderImages = async (category, folderPath) => {
    try {
      setIsLoadingGallery(true);
      setGalleryError('');
      
      console.log(`Fetching images from: category=${category}, folder=${folderPath}`);
      
      let url;
      if (!folderPath || folderPath === '') {
        // For direct image folders, don't include the folderPath in the URL
        // Encode the category to handle special characters
        const encodedCategory = encodeURIComponent(category);
        url = `${API_URL}/gallery/folder/${encodedCategory}`;
      } else {
        // Encode both category and folderPath
        const encodedCategory = encodeURIComponent(category);
        const encodedFolderPath = encodeURIComponent(folderPath);
        url = `${API_URL}/gallery/folder/${encodedCategory}/${encodedFolderPath}`;
      }
      
      console.log('Request URL:', url);
      
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        console.log(`Received ${data.images.length} images:`, data.images);
        setGalleryImages(data.images);
        setFolderImages(data.images);
        setCurrentFolder({ category, path: folderPath });
      } else {
        const errorText = await response.text();
        console.error('Response status:', response.status);
        console.error('Error response:', errorText);
        
        try {
          const errorData = JSON.parse(errorText);
          console.error('Parsed error:', errorData);
          setGalleryError(`Failed to load folder images: ${errorData.message || JSON.stringify(errorData)}`);
        } catch (e) {
          setGalleryError(`Failed to load folder images: ${errorText}`);
        }
      }
    } catch (error) {
      console.error('Error fetching folder images:', error);
      setGalleryError('An error occurred while loading the images');
    } finally {
      setIsLoadingGallery(false);
    }
  };

  // Return to folders view
  const handleBackToFolders = () => {
    setCurrentFolder(null);
    setGalleryImages([]);
  };

  // Handle folder click
  const handleFolderClick = (category, folder) => {
    console.log('Folder clicked:', category, folder);
    
    // Make sure category is handled correctly
    const categoryName = typeof category === 'object' ? category.name : category;
    
    // Handle direct image folders (which have empty path)
    const folderPath = folder.path || '';
    
    console.log(`Requesting images for category=${categoryName}, folderPath=${folderPath}`);
    fetchFolderImages(categoryName, folderPath);
  };

  // Calculate ability modifier
  const getAbilityModifier = (score) => {
    if (!score) return 0;
    return Math.floor((score - 10) / 2);
  };

  // Render HTML content safely using an iframe to isolate the content
  const createIframeSrc = (content) => {
    const htmlContent = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <style>
    body {
      margin: 0;
      padding: 0 0 40px 0;
      font-family: 'Crimson Text', serif;
      color: #e2e8f0;
      background-color: transparent;
      font-size: 16px;
      line-height: 1.5;
    }
    
    /* Only paragraphs of content should be visible */
    p {
      margin-bottom: 16px;
    }
    
    /* Always ensure links are visible */
    a { color: #ffd700; text-decoration: underline; }
  </style>
</head>
<body>${content || ''}</body>
</html>`;
    
    return `data:text/html;charset=utf-8,${encodeURIComponent(htmlContent)}`;
  };

  // Backwards compatibility wrapper for the old renderHtml function
  const renderHtml = (html) => {
    return { __html: html };
  };

  // Handle avatar upload
  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Check file size (max 2MB)
    if (file.size > 2 * 1024 * 1024) {
      setUploadError('File too large. Maximum size is 2MB.');
      return;
    }
    
    // Check file type
    if (!file.type.match('image.*')) {
      setUploadError('Only image files are allowed.');
      return;
    }
    
    setIsUploading(true);
    setUploadError('');
    
    try {
      const formData = new FormData();
      formData.append('avatar', file);
      
      const response = await fetch(`${API_URL}/avatar/upload`, {
        method: 'POST',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: formData
      });
      
      if (!response.ok) {
        throw new Error('Failed to upload avatar');
      }
      
      // Fetch the new avatar
      await fetchAvatar();
      
    } catch (err) {
      console.error('Error uploading avatar:', err);
      setUploadError('Failed to upload avatar');
    } finally {
      setIsUploading(false);
      e.target.value = '';
    }
  };

  // Format date string
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  // Handle tab selection
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Handle logout
  const handleLogout = () => {
    logout();
  };

  // Function to refresh character sheet data
  const refreshCharacterSheet = async () => {
    setIsLoadingCharacter(true);
    setCharacterSheet(null);
    setCharacterError('');
    await fetchCharacterSheet();
  };

  // Handle character sheet upload
  const handleCharacterSheetUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Check file type
    if (!file.name.endsWith('.json')) {
      setCharacterError('Invalid file type. Please upload a JSON file.');
      return;
    }

    // Check file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setCharacterError('File too large. Maximum size is 5MB.');
      return;
    }

    setIsLoadingCharacter(true);
    setCharacterError('');
    // Clear the current character sheet data before uploading new data
    setCharacterSheet(null);
    
    // Read the file contents first to ensure it's valid JSON
    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          // Validate JSON first
          const fileContent = event.target.result;
          const parsedData = JSON.parse(fileContent); 
          
          // Basic validation to check if it's a character sheet
          if (!parsedData.name) {
            throw new Error('Invalid character sheet format: Missing character name');
          }
          
          // If JSON is valid, proceed with the upload
          const formData = new FormData();
          formData.append('characterSheet', file);

          const response = await fetch(`${API_URL}/adventurer/upload/character-sheet`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: formData
          });

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to upload character sheet');
          }

          // Successfully uploaded, now fetch the character sheet
          await fetchCharacterSheet();
        } catch (error) {
          console.error('Error processing or uploading character sheet:', error);
          setCharacterError(error.message || 'Invalid JSON file or failed to upload character sheet');
          setIsLoadingCharacter(false);
        }
      };
      
      reader.onerror = () => {
        setCharacterError('Error reading the file');
        setIsLoadingCharacter(false);
      };
      
      reader.readAsText(file);
    } catch (error) {
      console.error('Error uploading character sheet:', error);
      setCharacterError(error.message || 'Failed to upload character sheet');
      setIsLoadingCharacter(false);
    }
  };

  // Add this new helper function before renderCharacterContent
  const getDisplayValue = (key, value) => {
    // Special debug handling for race/background/class: examine the full object
    // console.log(`Getting display value for ${key}:`, value);
    
    // If value doesn't exist, return Unknown
    if (value === null || value === undefined || value === '') return 'Unknown';
    
    // Special handling for specific keys
    if (key === 'class') {
      // First look for class items which are more reliable
      if (characterSheet.items && Array.isArray(characterSheet.items)) {
        const classItems = characterSheet.items.filter(item => 
          item.type === 'class' && item.name && item.name.trim() !== ''
        );
        if (classItems.length > 0) {
          // Join multiple class names if there are several
          return classItems.map(item => item.name).join(' / ');
        }
      }
    }
    
    if (key === 'race') {
      // Check if we have a race item
      if (characterSheet.items && Array.isArray(characterSheet.items)) {
        const raceItem = characterSheet.items.find(item => 
          item.type === 'race' || item.type === 'ancestry' || item.type === 'heritage'
        );
        if (raceItem && raceItem.name) return raceItem.name;
      }
      
      // Otherwise try to extract from various locations
      if (characterSheet.system && characterSheet.system.traits && characterSheet.system.traits.race) {
        return characterSheet.system.traits.race;
      }
    }
    
    if (key === 'background') {
      // Check if we have a background item
      if (characterSheet.items && Array.isArray(characterSheet.items)) {
        const bgItem = characterSheet.items.find(item => item.type === 'background');
        if (bgItem && bgItem.name) return bgItem.name;
      }
    }
    
    if (key === 'alignment') {
      // Common alignment codes
      const alignments = {
        'lg': 'Lawful Good',
        'ng': 'Neutral Good',
        'cg': 'Chaotic Good',
        'ln': 'Lawful Neutral',
        'n': 'Neutral',
        'cn': 'Chaotic Neutral',
        'le': 'Lawful Evil',
        'ne': 'Neutral Evil',
        'ce': 'Chaotic Evil'
      };
      
      // Check if this is a known alignment code
      if (typeof value === 'string' && alignments[value.toLowerCase()]) {
        return alignments[value.toLowerCase()];
      }
    }
    
    if (key === 'originalClass') {
      // Check for class item
      if (characterSheet.items && Array.isArray(characterSheet.items)) {
        const classItem = characterSheet.items.find(item => 
          item.type === 'class' && item.name && item.name.trim() !== ''
        );
        if (classItem) return classItem.name;
      }
    }
    
    // If value is a simple string that's not an ID (no periods or @), return it
    if (typeof value === 'string') {
      // Clean up compendium references
      if (value.includes('@Compendium')) {
        const match = value.match(/\[([^\]]+)\]/);
        if (match && match[1]) {
          const parts = match[1].split('.');
          if (parts.length > 0) {
            // Get the last part and clean it
            const name = parts[parts.length - 1]
              .replace(/[0-9]+$/, '') // Remove trailing numbers
              .replace(/([A-Z])/g, ' $1') // Add spaces before capital letters
              .replace(/^[^a-zA-Z]+/, '') // Remove leading non-alphabetic chars
              .trim();
              
            if (name) return name.charAt(0).toUpperCase() + name.slice(1);
          }
        }
      }
      
      // For dnd5e.X.Y style IDs (e.g., dnd5e.races.elf)
      if (value.includes('.')) {
        const parts = value.split('.');
        if (parts.length > 0) {
          return parts[parts.length - 1]
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        }
      }
      
      // For unrecognized strings that look like IDs
      if (/^[A-Za-z0-9]{10,}$/.test(value)) {
        // This looks like an ID - try to find the item
        if (characterSheet.items && Array.isArray(characterSheet.items)) {
          // Look for an item with this ID or name containing this
          const item = characterSheet.items.find(item => 
            item._id === value || 
            (item.name && item.name.includes(value))
          );
          if (item && item.name) return item.name;
        }
        
        // If we can't find an item, this might be a code - return Unknown
        return 'Unknown';
      }
      
      // For regular strings, just return as is
      return value;
    }
    
    // For numeric values
    if (typeof value === 'number') {
      return value.toString();
    }
    
    // For anything else, return string representation
    return String(value);
  };

  // Handle tab selection within character sheet
  const handleCharacterTabClick = (tab) => {
    setCharacterTabActive(tab);
  };

  // Add event listener for tab clicks from the HTML content
  useEffect(() => {
    // Event delegation to handle tab clicks
    function handleTabClick(event) {
      // Find if the click was on a tab button or one of its children
      let target = event.target;
      while (target && !target.classList?.contains('character-tab-button')) {
        // If we reach the document body or an element with no parent, break
        if (target === document.body || !target.parentElement) {
          return;
        }
        target = target.parentElement;
      }
      
      // If we found a tab button
      if (target && target.classList.contains('character-tab-button')) {
        const tabName = target.getAttribute('data-tab');
        if (tabName) {
          handleCharacterTabClick(tabName);
        }
      }
    }
    
    // Add the event listener to the document
    document.addEventListener('click', handleTabClick);
    
    return () => {
      document.removeEventListener('click', handleTabClick);
    };
  }, []);

  // Render character sheet content
  const renderCharacterSheet = () => {
    if (isLoadingCharacter) {
      return <div className="loading-character">Loading character sheet...</div>;
    }

    if (characterError) {
      return <div className="character-error">{characterError}</div>;
    }

    if (!characterSheet) {
      return (
        <div className="no-character-sheet">
          <h4>No Character Sheet Found</h4>
          <p>Upload your character sheet to see it displayed here.</p>
          
          {/* Character sheet tabs - even when there's no sheet */}
          <div className="character-sheet-tabs">
            <button 
              className={`character-tab-button ${characterTabActive === 'details' ? 'active' : ''}`} 
              onClick={() => handleCharacterTabClick('details')}
              data-tab="details"
            >
              Details & Abilities
            </button>
            <button 
              className={`character-tab-button ${characterTabActive === 'equipment' ? 'active' : ''}`} 
              onClick={() => handleCharacterTabClick('equipment')}
              data-tab="equipment"
            >
              Equipment & Currency
            </button>
            <button 
              className={`character-tab-button ${characterTabActive === 'spells' ? 'active' : ''}`} 
              onClick={() => handleCharacterTabClick('spells')}
              data-tab="spells"
            >
              Spells
            </button>
            <button 
              className={`character-tab-button ${characterTabActive === 'biography' ? 'active' : ''}`} 
              onClick={() => handleCharacterTabClick('biography')}
              data-tab="biography"
            >
              Biography
            </button>
          </div>
          
          {/* Tab content container */}
          <div className="character-sheet-tab-content">
            {/* Tab 1: Details */}
            <div className={`character-tab-pane ${characterTabActive === 'details' ? 'active' : ''}`} id="details-tab">
              <div className="no-items">No character details available. Upload a character sheet to see information here.</div>
            </div>
            
            {/* Tab 2: Equipment */}
            <div className={`character-tab-pane ${characterTabActive === 'equipment' ? 'active' : ''}`} id="equipment-tab">
              <div className="no-items">No equipment data available. Upload a character sheet to see information here.</div>
            </div>
            
            {/* Tab 3: Spells */}
            <div className={`character-tab-pane ${characterTabActive === 'spells' ? 'active' : ''}`} id="spells-tab">
              <div className="no-items">No spell data available. Upload a character sheet to see information here.</div>
            </div>
            
            {/* Tab 4: Biography */}
            <div className={`character-tab-pane ${characterTabActive === 'biography' ? 'active' : ''}`} id="biography-tab">
              <div className="no-items">No biography information available. Upload a character sheet to see information here.</div>
            </div>
          </div>
          
          <label className="upload-character-button">
            Upload Character Sheet
            <input
              type="file"
              accept=".json"
              onChange={handleCharacterSheetUpload}
            />
          </label>
        </div>
      );
    }

    // Create the character header HTML with name and portrait
    const characterHeader = () => {
      return (
        <div className="character-header">
          <h2 className="character-name">{characterSheet.name || 'Unnamed Character'}</h2>
          <div className="character-portrait">
            {avatarUrl ? (
              <img src={avatarUrl} alt={characterSheet.name || 'Character'} />
            ) : (
              <div className="character-portrait-placeholder">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="64" height="64">
                  <path fill="#8B0000" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
                </svg>
              </div>
            )}
          </div>
          {characterPdfUrl && (
            <div className="character-pdf-download">
              <a 
                href={`${characterPdfUrl}&token=${encodeURIComponent(localStorage.getItem('auth_token'))}`}
                target="_blank"
                rel="noopener noreferrer"
                className="pdf-download-button"
              >
                Download PDF
              </a>
            </div>
          )}
        </div>
      );
    };

    // Render the tabs and the appropriate tab content based on active tab state
    return (
      <div className="character-sheet-content">
        {/* Character content header */}
        {characterHeader()}
        
        {/* Character sheet tabs */}
        <div className="character-sheet-tabs">
          <button 
            className={`character-tab-button ${characterTabActive === 'details' ? 'active' : ''}`} 
            onClick={() => handleCharacterTabClick('details')}
            data-tab="details"
          >
            Details & Abilities
          </button>
          <button 
            className={`character-tab-button ${characterTabActive === 'equipment' ? 'active' : ''}`} 
            onClick={() => handleCharacterTabClick('equipment')}
            data-tab="equipment"
          >
            Equipment & Currency
          </button>
          <button 
            className={`character-tab-button ${characterTabActive === 'spells' ? 'active' : ''}`} 
            onClick={() => handleCharacterTabClick('spells')}
            data-tab="spells"
          >
            Spells
          </button>
          <button 
            className={`character-tab-button ${characterTabActive === 'biography' ? 'active' : ''}`} 
            onClick={() => handleCharacterTabClick('biography')}
            data-tab="biography"
          >
            Biography
          </button>
        </div>
        
        {/* Tab content container - only render the active tab */}
        <div className="character-sheet-tab-content">
          {characterTabActive === 'details' && (
            <div className="character-tab-pane active" id="details-tab" 
                 dangerouslySetInnerHTML={{ __html: renderDetailsTab() }} />
          )}
          
          {characterTabActive === 'equipment' && (
            <div className="character-tab-pane active" id="equipment-tab" 
                 dangerouslySetInnerHTML={{ __html: renderEquipmentTab() }} />
          )}
          
          {characterTabActive === 'spells' && (
            <div className="character-tab-pane active" id="spells-tab" 
                 dangerouslySetInnerHTML={{ __html: renderSpellsTab() }} />
          )}
          
          {characterTabActive === 'biography' && (
            <div className="character-tab-pane active" id="biography-tab" 
                 dangerouslySetInnerHTML={{ __html: renderBiographyTab() }} />
          )}
        </div>
        
        <div className="character-sheet-refresh">
          <button 
            className="refresh-button" 
            onClick={refreshCharacterSheet}
            disabled={isLoadingCharacter}
          >
            {isLoadingCharacter ? 'Refreshing...' : 'Refresh Character Sheet'}
          </button>
        </div>
      </div>
    );
  };

  // Function to render just the details tab content
  const renderDetailsTab = () => {
    if (!characterSheet) return '';
    let html = '';
    
    // Character details section
    html += '<div class="character-details-section">';
    html += '<h3>CHARACTER DETAILS</h3>';
    
    // Create a grid layout for character details
    html += '<div class="character-details-grid">';
    
    // Create individual detail boxes with labels above values
    // Use parsedCharacter if available, fall back to original method
    const race = parsedCharacter?.details?.race || getDisplayValue('race', characterSheet.system?.details?.race);
    const background = parsedCharacter?.details?.background || getDisplayValue('background', characterSheet.system?.details?.background);
    const alignment = parsedCharacter?.details?.alignment || getDisplayValue('alignment', characterSheet.system?.details?.alignment);
    const xp = getNestedValue(characterSheet, 'system.details.xp.value', '0');
    const originalClass = parsedCharacter?.details?.class || getDisplayValue('originalClass', characterSheet.system?.details?.originalClass);
    
    html += `
      <div class="detail-box">
        <div class="detail-label">Race</div>
        <div class="detail-value">${race}</div>
      </div>
      
      <div class="detail-box">
        <div class="detail-label">Background</div>
        <div class="detail-value">${background}</div>
      </div>
      
      <div class="detail-box">
        <div class="detail-label">Alignment</div>
        <div class="detail-value">${alignment}</div>
      </div>
      
      <div class="detail-box">
        <div class="detail-label">XP</div>
        <div class="detail-value">${xp}</div>
      </div>
      
      <div class="detail-box">
        <div class="detail-label">Original Class</div>
        <div class="detail-value">${originalClass}</div>
      </div>
    `;
    
    html += '</div>'; // End character details grid
    html += '</div>'; // End character details section
    
    // Abilities section
    html += '<div class="abilities-section">';
    html += '<h3>ABILITIES</h3>';
    html += '<div class="abilities-container">';
    
    // Use parsed abilities if available, fall back to original
    if (parsedCharacter?.abilities && Object.keys(parsedCharacter.abilities).length > 0) {
      // Use the new parsed ability scores with pre-calculated modifiers
      Object.entries(parsedCharacter.abilities).forEach(([key, ability]) => {
        const sign = ability.mod >= 0 ? '+' : '';
        
        html += `
          <div class="ability-score">
            <div class="ability-name">${key.toUpperCase()}</div>
            <div class="ability-circle">
              <span class="ability-value">${ability.value}</span>
            </div>
            <div class="ability-modifier">${sign}${ability.mod}</div>
            ${ability.proficient ? '<div class="ability-proficient">Proficient</div>' : ''}
          </div>
        `;
      });
    } else if (characterSheet.system?.abilities) {
      // Fallback to original method
      Object.entries(characterSheet.system.abilities).forEach(([key, ability]) => {
        const mod = getAbilityModifier(ability.value);
        const sign = mod >= 0 ? '+' : '';
        
        html += `
          <div class="ability-score">
            <div class="ability-name">${key.toUpperCase()}</div>
            <div class="ability-circle">
              <span class="ability-value">${ability.value !== undefined && ability.value !== null ? ability.value : ''}</span>
            </div>
            <div class="ability-modifier">${sign}${mod}</div>
            ${ability.proficient ? '<div class="ability-proficient">Proficient</div>' : ''}
          </div>
        `;
      });
    }
    
    html += '</div>'; // End abilities container
    html += '</div>'; // End abilities section
    
    // Combat Stats Section - EXPANDED with all stats
    html += '<div class="combat-section">';
    html += '<h3>Combat</h3>';
    html += '<div class="combat-stats">';
    
    // HP section - use parsed data if available
    if (parsedCharacter?.attributes?.hp) {
      const hp = parsedCharacter.attributes.hp;
      const hpPercentage = Math.min(100, Math.max(0, ((hp.value || 0) / (hp.max || 1)) * 100));
      
      html += `
        <div class="combat-stat hp-container">
          <div class="stat-name">Hit Points</div>
          <div class="hp-values">
            <span class="current-hp">${hp.value || 0}</span>
            <span class="hp-separator">/</span>
            <span class="max-hp">${hp.max || 0}</span>
          </div>
          <div class="hp-bar">
            <div class="hp-fill" style="width: ${hpPercentage}%"></div>
            <div class="hp-text">${hp.value || 0} / ${hp.max || 0}</div>
          </div>
          ${hp.temp ? `<div class="temp-hp">Temp HP: ${hp.temp}</div>` : ''}
        </div>
      `;
    } else if (characterSheet.system?.attributes?.hp) {
      // Fallback to original
      const hp = characterSheet.system.attributes.hp;
      const hpPercentage = Math.min(100, Math.max(0, ((hp.value || 0) / (hp.max || 1)) * 100));
      
      html += `
        <div class="combat-stat hp-container">
          <div class="stat-name">Hit Points</div>
          <div class="hp-values">
            <span class="current-hp">${hp.value || 0}</span>
            <span class="hp-separator">/</span>
            <span class="max-hp">${hp.max || 0}</span>
          </div>
          <div class="hp-bar">
            <div class="hp-fill" style="width: ${hpPercentage}%"></div>
            <div class="hp-text">${hp.value || 0} / ${hp.max || 0}</div>
          </div>
          ${hp.temp ? `<div class="temp-hp">Temp HP: ${hp.temp}</div>` : ''}
        </div>
      `;
    }
    
    // Core combat stats - use parsed data if available
    const combatStats = [
      { 
        name: 'Armor Class', 
        value: parsedCharacter?.attributes?.ac !== undefined 
          ? parsedCharacter.attributes.ac 
          : (characterSheet.system?.attributes?.ac?.value !== undefined ? characterSheet.system?.attributes?.ac?.value : '') 
      },
      { 
        name: 'Initiative', 
        value: parsedCharacter?.attributes?.initiative !== undefined 
          ? parsedCharacter.attributes.initiative
          : (characterSheet.system?.attributes?.init?.total !== undefined ? characterSheet.system?.attributes?.init?.total : '') 
      },
      { 
        name: 'Speed', 
        value: parsedCharacter?.attributes?.speed !== undefined 
          ? `${parsedCharacter.attributes.speed} ft`
          : (characterSheet.system?.attributes?.movement?.walk !== undefined ? `${characterSheet.system?.attributes?.movement?.walk} ft` : '') 
      },
      { 
        name: 'Proficiency', 
        value: parsedCharacter?.attributes?.profBonus !== undefined 
          ? parsedCharacter.attributes.profBonus
          : (characterSheet.system?.attributes?.prof?.value !== undefined ? characterSheet.system?.attributes?.prof?.value : '') 
      }
    ];
    
    combatStats.forEach(stat => {
      html += `
        <div class="combat-stat">
          <div class="stat-name">${stat.name}</div>
          <div class="stat-value">${stat.value}</div>
        </div>
      `;
    });
    
    // Add other movement types if they exist
    if (characterSheet.system?.attributes?.movement) {
      const movement = characterSheet.system.attributes.movement;
      Object.entries(movement).forEach(([key, value]) => {
        if (key !== 'walk' && value) {
          html += `
            <div class="combat-stat">
              <div class="stat-name">${key.charAt(0).toUpperCase() + key.slice(1)} Speed</div>
              <div class="stat-value">${value} ft</div>
            </div>
          `;
        }
      });
    }
    
    // Add hit dice if they exist
    if (characterSheet.system?.attributes?.hd) {
      const hd = characterSheet.system.attributes.hd;
      html += `
        <div class="combat-stat">
          <div class="stat-name">Hit Dice</div>
          <div class="stat-value">${hd.value || 0}/${hd.max || 0} ${hd.die || 'd8'}</div>
        </div>
      `;
    }
    
    // Add death saves if they exist
    if (characterSheet.system?.attributes?.death) {
      const death = characterSheet.system.attributes.death;
      html += `
        <div class="combat-stat death-saves">
          <div class="stat-name">Death Saves</div>
          <div class="death-save-boxes">
            <div class="success-saves">
              <span>Successes: </span>
              <span class="save-box ${death.success >= 1 ? 'filled' : ''}"></span>
              <span class="save-box ${death.success >= 2 ? 'filled' : ''}"></span>
              <span class="save-box ${death.success >= 3 ? 'filled' : ''}"></span>
            </div>
            <div class="failure-saves">
              <span>Failures: </span>
              <span class="save-box ${death.failure >= 1 ? 'filled' : ''}"></span>
              <span class="save-box ${death.failure >= 2 ? 'filled' : ''}"></span>
              <span class="save-box ${death.failure >= 3 ? 'filled' : ''}"></span>
            </div>
          </div>
        </div>
      `;
    }
    
    html += '</div>'; // End combat stats
    html += '</div>'; // End combat section
    
    // Skills Section - EXPANDED with all skills and details
    html += '<div class="skills-section">';
    html += '<h3>Skills</h3>';
    html += '<div class="skills-container">';
    
    if (characterSheet.system) {
      // Calculate proficiency bonus based on level
      const level = characterSheet.system?.details?.level || 1;
      const proficiencyBonus = Math.floor((level - 1) / 4) + 2;
      
      // Define skills in D&D character sheet order with full names
      const dndSkillOrder = [
        { key: 'acr', name: 'Acrobatics', ability: 'dex' },
        { key: 'ani', name: 'Animal Handling', ability: 'wis' },
        { key: 'arc', name: 'Arcana', ability: 'int' },
        { key: 'ath', name: 'Athletics', ability: 'str' },
        { key: 'dec', name: 'Deception', ability: 'cha' },
        { key: 'his', name: 'History', ability: 'int' },
        { key: 'ins', name: 'Insight', ability: 'wis' },
        { key: 'itm', name: 'Intimidation', ability: 'cha' },
        { key: 'inv', name: 'Investigation', ability: 'int' },
        { key: 'med', name: 'Medicine', ability: 'wis' },
        { key: 'nat', name: 'Nature', ability: 'int' },
        { key: 'prc', name: 'Perception', ability: 'wis' },
        { key: 'prf', name: 'Performance', ability: 'cha' },
        { key: 'per', name: 'Persuasion', ability: 'cha' },
        { key: 'rel', name: 'Religion', ability: 'int' },
        { key: 'slt', name: 'Sleight of Hand', ability: 'dex' },
        { key: 'ste', name: 'Stealth', ability: 'dex' },
        { key: 'sur', name: 'Survival', ability: 'wis' }
      ];
      
      // Render skills in the specified order
      dndSkillOrder.forEach(orderedSkill => {
        // Get the skill data from the character sheet
        const skills = characterSheet.system.skills || {};
        const skill = skills[orderedSkill.key];
        
        // Get the corresponding ability score
        const abilityKey = orderedSkill.ability;
        const abilityScore = characterSheet.system.abilities?.[abilityKey]?.value || 10;
        const abilityMod = getAbilityModifier(abilityScore);
        
        if (skill) {
          // Calculate the skill modifier
          // value: 0 = not proficient, 1 = proficient, 2 = expertise
          const profValue = skill.value || 0;
          const isProficient = profValue > 0;
          const hasExpertise = profValue > 1;
          
          // Calculate the total modifier: ability modifier + (proficiency value × proficiency bonus)
          const totalMod = abilityMod + (profValue * proficiencyBonus);
          const formattedMod = totalMod >= 0 ? `+${totalMod}` : `${totalMod}`;
          
          // Display the ability name in uppercase
          const displayAbility = abilityKey.toUpperCase();
          
          html += `
            <div class="skill-item">
              <div class="skill-proficient ${isProficient ? 'active' : ''}"></div>
              <div class="skill-name">${orderedSkill.name} (${displayAbility})</div>
              <div class="skill-modifier">${formattedMod}</div>
              ${hasExpertise ? '<div class="skill-expertise">Expertise</div>' : ''}
            </div>
          `;
        } else {
          // If skill is not defined, just show the base ability modifier
          const formattedMod = abilityMod >= 0 ? `+${abilityMod}` : `${abilityMod}`;
          const displayAbility = abilityKey.toUpperCase();
          
          html += `
            <div class="skill-item">
              <div class="skill-proficient"></div>
              <div class="skill-name">${orderedSkill.name} (${displayAbility})</div>
              <div class="skill-modifier">${formattedMod}</div>
            </div>
          `;
        }
      });
    } else {
      // If no skills at all, create a placeholder
      html += '<div class="no-items">No skills found</div>';
    }
    
    html += '</div>'; // End skills container
    html += '</div>'; // End skills section
    
    // Features section
    html += '<div class="features-section">';
    html += '<h3>Features & Traits</h3>';
    html += '<div class="features-list">';
    
    if (characterSheet.items) {
      const features = characterSheet.items.filter(item => 
        item.type === 'feat' || item.type === 'feature'
      );
      
      if (features.length === 0) {
        html += '<div class="no-items">No features found</div>';
      } else {
        // Sort by source (class, race, etc.) - FIX sorting to handle non-string values
        features
          .sort((a, b) => {
            // Ensure sourceA and sourceB are strings
            const sourceA = String(a.system?.source || '');
            const sourceB = String(b.system?.source || '');
            if (sourceA === sourceB) return String(a.name || '').localeCompare(String(b.name || ''));
            return sourceA.localeCompare(sourceB);
          })
          .forEach(item => {
            const source = item.system?.source ? ` (${item.system.source})` : '';
            
            html += `
              <div class="feature-item">
                <div class="feature-name">${item.name}${source}</div>
                <div class="feature-description">
                  ${item.system?.description?.value 
                    ? `<div>${item.system.description.value.replace(/<\/?[^>]+(>|$)/g, " ")}</div>`
                    : '<p>No description available</p>'
                  }
                </div>
              </div>
            `;
          });
      }
    } else {
      html += '<div class="no-items">No features found</div>';
    }
    
    html += '</div>'; // End features list
    html += '</div>'; // End features section
    
    return html;
  };

  // Function to render just the equipment tab content
  const renderEquipmentTab = () => {
    if (!characterSheet) return '';
    let html = '';
    
    // Currency section if it exists - use parsed data if available
    if (parsedCharacter?.currency) {
      html += '<div class="currency-section">';
      html += '<h3>Currency</h3>';
      html += '<div class="currency-container">';
      
      const currencyTypes = [
        { key: 'pp', name: 'Platinum' },
        { key: 'gp', name: 'Gold' },
        { key: 'ep', name: 'Electrum' },
        { key: 'sp', name: 'Silver' },
        { key: 'cp', name: 'Copper' }
      ];
      
      currencyTypes.forEach(type => {
        html += `
          <div class="currency-item">
            <span class="currency-name">${type.name}</span>
            <span class="currency-value">${parsedCharacter.currency[type.key] || 0}</span>
          </div>
        `;
      });
      
      html += '</div>'; // End currency container
      html += '</div>'; // End currency section
    } else if (characterSheet.system?.currency) {
      // Fallback to original method
      html += '<div class="currency-section">';
      html += '<h3>Currency</h3>';
      html += '<div class="currency-container">';
      
      const currency = characterSheet.system.currency;
      const currencyTypes = [
        { key: 'pp', name: 'Platinum' },
        { key: 'gp', name: 'Gold' },
        { key: 'ep', name: 'Electrum' },
        { key: 'sp', name: 'Silver' },
        { key: 'cp', name: 'Copper' }
      ];
      
      currencyTypes.forEach(type => {
        html += `
          <div class="currency-item">
            <span class="currency-name">${type.name}</span>
            <span class="currency-value">${currency[type.key] || 0}</span>
          </div>
        `;
      });
      
      html += '</div>'; // End currency container
      html += '</div>'; // End currency section
    }
    
    // Equipment section - NOW AFTER currency
    html += '<div class="equipment-section">';
    html += '<h3>Equipment</h3>';
    html += '<div class="equipment-list">';
    
    // Use parsed equipment items if available
    if (parsedCharacter?.itemsByType) {
      // Get equipment from parsed items
      const equipmentTypes = ['weapon', 'equipment', 'tool', 'consumable', 'container', 'backpack', 'loot'];
      const equipment = [];
      
      // Collect all equipment items from the different types
      equipmentTypes.forEach(type => {
        if (Array.isArray(parsedCharacter.itemsByType[type])) {
          equipment.push(...parsedCharacter.itemsByType[type]);
        }
      });
      
      if (equipment.length === 0) {
        html += '<div class="no-items">No equipment found</div>';
      } else {
        // Sort equipment by type and name
        equipment
          .sort((a, b) => {
            // Ensure type and name are strings
            const typeA = String(a.type || '');
            const typeB = String(b.type || '');
            const nameA = String(a.name || '');
            const nameB = String(b.name || '');
            
            if (typeA === typeB) return nameA.localeCompare(nameB);
            return typeA.localeCompare(typeB);
          })
          .forEach(item => {
            // Render equipment item using the same format as the original code
            const itemType = item.type ? String(item.type).charAt(0).toUpperCase() + String(item.type).slice(1) : 'Item';
            const quantity = item.system?.quantity ? ` (×${item.system.quantity})` : '';
            const weight = item.system?.weight ? ` - ${item.system.weight} lbs` : '';
            
            html += `
              <div class="equipment-item">
                <div class="equipment-name">${item.name || 'Unknown Item'}${quantity}</div>
                <div class="equipment-type">${itemType}${weight}</div>
                <div class="equipment-description">
                  ${item.system?.description?.value 
                    ? `<div>${item.system.description.value.replace(/<\/?[^>]+(>|$)/g, " ")}</div>`
                    : '<p>No description available</p>'
                  }
                </div>
            `;
            
            // Add weapon-specific properties
            if (item.type === 'weapon' && item.system) {
              const weapon = item.system;
              html += '<div class="weapon-properties">';
              
              if (weapon.damage) {
                html += `<div class="weapon-damage">Damage: ${weapon.damage.parts?.[0]?.[0] || ''} ${weapon.damage.parts?.[0]?.[1] || ''}</div>`;
              }
              
              if (weapon.attackBonus) {
                html += `<div class="weapon-attack">Attack Bonus: ${weapon.attackBonus}</div>`;
              }
              
              if (weapon.properties) {
                const props = Object.entries(weapon.properties)
                  .filter(([, value]) => value === true)
                  .map(([key]) => key ? key.charAt(0).toUpperCase() + key.slice(1) : '')
                  .join(', ');
                  
                if (props) {
                  html += `<div class="weapon-props">Properties: ${props}</div>`;
                }
              }
              
              if (weapon.range) {
                html += `<div class="weapon-range">Range: ${weapon.range.value || 0}/${weapon.range.long || 0} ft</div>`;
              }
              
              html += '</div>'; // End weapon properties
            }
            
            html += '</div>'; // End equipment item
          });
      }
    } else if (characterSheet.items) {
      // Fallback to original implementation
      // Group equipment by type
      const equipment = characterSheet.items.filter(item => 
        ['weapon', 'equipment', 'tool', 'consumable', 'container', 'backpack', 'loot'].includes(item.type)
      );
      
      if (equipment.length === 0) {
        html += '<div class="no-items">No equipment found</div>';
      } else {
        // Sort equipment by type and name
        equipment
          .sort((a, b) => {
            // Ensure type and name are strings
            const typeA = String(a.type || '');
            const typeB = String(b.type || '');
            const nameA = String(a.name || '');
            const nameB = String(b.name || '');
            
            if (typeA === typeB) return nameA.localeCompare(nameB);
            return typeA.localeCompare(typeB);
          })
          .forEach(item => {
            const itemType = item.type ? String(item.type).charAt(0).toUpperCase() + String(item.type).slice(1) : 'Item';
            const quantity = item.system?.quantity ? ` (×${item.system.quantity})` : '';
            const weight = item.system?.weight ? ` - ${item.system.weight} lbs` : '';
            
            html += `
              <div class="equipment-item">
                <div class="equipment-name">${item.name || 'Unknown Item'}${quantity}</div>
                <div class="equipment-type">${itemType}${weight}</div>
                <div class="equipment-description">
                  ${item.system?.description?.value 
                    ? `<div>${item.system.description.value.replace(/<\/?[^>]+(>|$)/g, " ")}</div>`
                    : '<p>No description available</p>'
                  }
                </div>
            `;
            
            // Add weapon-specific properties
            if (item.type === 'weapon' && item.system) {
              const weapon = item.system;
              html += '<div class="weapon-properties">';
              
              if (weapon.damage) {
                html += `<div class="weapon-damage">Damage: ${weapon.damage.parts?.[0]?.[0] || ''} ${weapon.damage.parts?.[0]?.[1] || ''}</div>`;
              }
              
              if (weapon.attackBonus) {
                html += `<div class="weapon-attack">Attack Bonus: ${weapon.attackBonus}</div>`;
              }
              
              if (weapon.properties) {
                const props = Object.entries(weapon.properties)
                  .filter(([, value]) => value === true)
                  .map(([key]) => key ? key.charAt(0).toUpperCase() + key.slice(1) : '')
                  .join(', ');
                  
                if (props) {
                  html += `<div class="weapon-props">Properties: ${props}</div>`;
                }
              }
              
              if (weapon.range) {
                html += `<div class="weapon-range">Range: ${weapon.range.value || 0}/${weapon.range.long || 0} ft</div>`;
              }
              
              html += '</div>'; // End weapon properties
            }
            
            html += '</div>'; // End equipment item
          });
      }
    } else {
      html += '<div class="no-items">No equipment found</div>';
    }
    
    html += '</div>'; // End equipment list
    html += '</div>'; // End equipment section
    
    return html;
  };

  // Function to render just the spells tab content
  const renderSpellsTab = () => {
    if (!characterSheet) return '';
    let html = '';
    
    // Spells section
    html += '<div class="spells-section">';
    html += '<h3>Spells</h3>';
    
    // Spellcasting Ability
    if (characterSheet.system?.attributes?.spellcasting) {
      const spellAbility = characterSheet.system.attributes.spellcasting.ability;
      if (spellAbility) {
        html += `<div class="spellcasting-ability">Spellcasting Ability: ${spellAbility.toUpperCase()}</div>`;
      }
    }
    
    // Spell Slots
    if (characterSheet.system?.spells) {
      html += '<div class="spell-slots-container">';
      
      // Display spell slots by level
      for (let level = 1; level <= 9; level++) {
        const slotKey = `spell${level}`;
        if (characterSheet.system.spells[slotKey]) {
          const slots = characterSheet.system.spells[slotKey];
          html += `
            <div class="spell-slot-level">
              <div class="slot-level">Level ${level}</div>
              <div class="slot-values">
                <span class="slots-used">${slots.value || 0}</span>
                <span class="slots-separator">/</span>
                <span class="slots-total">${slots.max || 0}</span>
              </div>
            </div>
          `;
        }
      }
      
      html += '</div>'; // End spell slots container
    }
    
    // Group spells by level
    html += '<div class="spell-list-by-level">';
    
    // Initialize an array to hold spells by level
    const spellsByLevel = {};
    for (let i = 0; i <= 9; i++) {
      spellsByLevel[i] = [];
    }
    
    // Sort spells into level buckets
    console.log('Character Items:', characterSheet.items); // Log all items
    console.log('Character System:', characterSheet.system); // Log the system data
    
    // Check for spells in different possible locations
    if (Array.isArray(characterSheet.items)) {
      const spellItems = characterSheet.items.filter(item => 
        item.type === 'spell' || 
        item.type === 'power' || // Some systems use "power" instead of "spell"
        (item.flags && item.flags.spellbook) // Check for spellbook flags
      );
      console.log('Found Spell Items:', spellItems); // Log the filtered spell items
      
      spellItems.forEach(spell => {
        // Try to get level from different possible locations
        const level = spell.system?.level || 
                      spell.level || 
                      spell.data?.level || 
                      (spell.flags?.spellbook?.level) || 
                      0;
        spellsByLevel[level].push(spell);
      });
    } else {
      console.error('Character sheet items is not an array or is undefined:', characterSheet.items);
      // Add a placeholder message for no spells
      html += '<div class="no-items">No spells found or items data is invalid</div>';
    }
    
    // Create a section for each level that has spells
    for (let level = 0; level <= 9; level++) {
      if (spellsByLevel[level].length > 0) {
        const levelName = level === 0 ? 'Cantrips' : `Level ${level} Spells`;
        
        html += `<div class="spell-level-group">`;
        html += `<h4 class="spell-level-header">${levelName}</h4>`;
        
        // Sort spells alphabetically within each level
        spellsByLevel[level]
          .sort((a, b) => String(a.name || '').localeCompare(String(b.name || '')))
          .forEach(spell => {
            // Get additional spell details
            const prepared = spell.system?.preparation?.prepared ? ' (Prepared)' : '';
            const school = spell.system?.school ? ` - ${spell.system.school}` : '';
            const castTime = spell.system?.activation?.type ? ` - ${spell.system.activation.type}` : '';
            
            html += `
              <div class="spell-item">
                <div class="spell-name">${spell.name || 'Unknown Spell'}${prepared}</div>
                <div class="spell-meta">${castTime}${school}</div>
                <div class="spell-description">
                  ${spell.system?.description?.value 
                    ? `<div>${spell.system.description.value.replace(/<\/?[^>]+(>|$)/g, " ")}</div>`
                    : '<p>No description available</p>'
                  }
                </div>
              </div>
            `;
          });
        
        html += `</div>`; // End spell level group
      }
    }
    
    html += '</div>'; // End spell list by level
    html += '</div>'; // End spells section
    
    return html;
  };

  // Function to render just the biography tab content
  const renderBiographyTab = () => {
    if (!characterSheet) return '';
    let html = '';
    
    // Biography section
    html += '<div class="biography-section">';
    html += '<h3>Biography</h3>';
    
    // Biography section - check multiple possible locations
    console.log('Biography Data:', characterSheet.system?.details?.biography); // Log biography data
    console.log('Alternative Biography:', characterSheet.system?.description); // Log alternative location
    
    // Check multiple possible locations for biography data
    let bio = null;
    if (characterSheet.system?.details?.biography) {
      bio = characterSheet.system.details.biography;
    } else if (characterSheet.system?.description) {
      bio = characterSheet.system.description;
    } else if (characterSheet.system?.details?.description) {
      bio = characterSheet.system.details.description;
    } else if (characterSheet.description) {
      bio = characterSheet.description;
    }
    
    if (bio) {
      // Log each biography field
      console.log('Biography object found:', bio);
      
      // Check for bio.value or alternative fields
      if (bio.value || bio.text || bio.public) {
        const bioText = bio.value || bio.text || bio.public || '';
        html += `
          <div class="biography-entry">
            <h4>Biography</h4>
            <div class="biography-text">${bioText.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      }
      
      // Check for appearance field
      if (bio.appearance) {
        html += `
          <div class="biography-entry">
            <h4>Appearance</h4>
            <div class="biography-text">${bio.appearance.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      } else if (bio.physical) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Appearance</h4>
            <div class="biography-text">${bio.physical.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      }
      
      // Check for trait field
      if (bio.trait) {
        html += `
          <div class="biography-entry">
            <h4>Personality Traits</h4>
            <div class="biography-text">${bio.trait.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      } else if (bio.traits) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Personality Traits</h4>
            <div class="biography-text">${bio.traits.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      } else if (bio.personality) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Personality Traits</h4>
            <div class="biography-text">${bio.personality.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      }
      
      // Check for ideal field
      if (bio.ideal) {
        html += `
          <div class="biography-entry">
            <h4>Ideals</h4>
            <div class="biography-text">${bio.ideal.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      } else if (bio.ideals) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Ideals</h4>
            <div class="biography-text">${bio.ideals.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      }
      
      // Check for bond field
      if (bio.bond) {
        html += `
          <div class="biography-entry">
            <h4>Bonds</h4>
            <div class="biography-text">${bio.bond.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      } else if (bio.bonds) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Bonds</h4>
            <div class="biography-text">${bio.bonds.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      }
      
      // Check for flaw field
      if (bio.flaw) {
        html += `
          <div class="biography-entry">
            <h4>Flaws</h4>
            <div class="biography-text">${bio.flaw.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      } else if (bio.flaws) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Flaws</h4>
            <div class="biography-text">${bio.flaws.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      }
      
      // Check for backstory field
      if (bio.backstory) {
        html += `
          <div class="biography-entry">
            <h4>Backstory</h4>
            <div class="biography-text">${bio.backstory.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      } else if (bio.background) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Backstory</h4>
            <div class="biography-text">${bio.background.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      }
    } else {
      html += '<div class="no-items">No biography information found</div>';
    }
    
    html += '</div>'; // End biography section
    return html;
  };

  // Handle image click to open in modal
  const handleImageClick = (image) => {
    const index = galleryImages.findIndex(img => img.url === image.url);
    setCurrentImageIndex(index);
    setSelectedImage(image);
    
    // Enable pinch zoom by modifying the viewport meta tag
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (viewportMeta) {
      viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=5, user-scalable=yes');
    }
  };

  // Close the image modal
  const handleCloseModal = () => {
    setSelectedImage(null);
    
    // Reset viewport meta tag when closing the modal
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (viewportMeta) {
      viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no');
    }
  };

  // Handle next image in gallery
  const handleNextImage = () => {
    if (galleryImages.length <= 1) return;
    const nextIndex = (currentImageIndex + 1) % galleryImages.length;
    setCurrentImageIndex(nextIndex);
    setSelectedImage(galleryImages[nextIndex]);
  };

  // Handle previous image in gallery
  const handlePrevImage = () => {
    if (galleryImages.length <= 1) return;
    const prevIndex = (currentImageIndex - 1 + galleryImages.length) % galleryImages.length;
    setCurrentImageIndex(prevIndex);
    setSelectedImage(galleryImages[prevIndex]);
  };

  // Add CSS styles for character details grid layout and ability boxes
  useEffect(() => {
    // Create and append style element
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      .character-details-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        margin-bottom: 1.5rem;
      }
      
      .detail-box {
        background-color: rgba(0, 0, 0, 0.3);
        border: 1px solid #8B0000;
        padding: 0.5rem;
        text-align: center;
        border-radius: 4px;
      }
      
      .detail-label {
        font-weight: bold;
        color: #ffd700;
        margin-bottom: 0.3rem;
        text-transform: uppercase;
        font-size: 0.9rem;
      }
      
      .detail-value {
        color: #fff;
        font-size: 1rem;
        word-break: break-word;
      }
      
      /* Ability score styling */
      .abilities-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        margin-bottom: 1.5rem;
      }
      
      .ability-score {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #000;
        border: 1px solid #8B0000;
        border-radius: 4px;
        padding: 5px 5px 10px 5px;
        width: 100%;
        box-sizing: border-box;
      }
      
      .ability-name {
        font-weight: bold;
        color: #fff;
        font-size: 1.2rem;
        margin-bottom: 5px;
        text-align: center;
        width: 100%;
        border-bottom: 1px solid #8B0000;
        padding-bottom: 5px;
      }
      
      .ability-circle {
        width: 50px;
        height: 50px;
        background-color: #000;
        border: 2px solid #ff0000;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px 0;
      }
      
      .ability-value {
        font-size: 1.4rem;
        color: #fff;
        font-weight: bold;
      }
      
      .ability-modifier {
        font-size: 1.2rem;
        color: #fff;
        margin-top: 5px;
      }
      
      .ability-proficient {
        font-size: 0.8rem;
        color: #ffd700;
        margin-top: 5px;
      }
      
      /* Responsive styling */
      @media (max-width: 768px) {
        .character-details-grid {
          grid-template-columns: 1fr;
        }
        
        .abilities-container {
          grid-template-columns: repeat(2, 1fr);
        }
      }
      
      @media (max-width: 480px) {
        .abilities-container {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    `;
    document.head.appendChild(styleElement);
    
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  // Add handlePasswordChange function before the return statement
  const handlePasswordChange = async (e) => {
    e.preventDefault();
    
    // Reset states
    setPasswordError('');
    setPasswordSuccess('');
    
    // Validate passwords
    if (newPassword !== confirmPassword) {
      setPasswordError('New passwords do not match');
      return;
    }
    
    if (newPassword.length < 8) {
      setPasswordError('New password must be at least 8 characters long');
      return;
    }
    
    setIsChangingPassword(true);
    
    try {
      // Use the exact endpoint from the server.js file
      const response = await fetch(`${API_URL}/change-password`, {
        method: 'PUT',  // The server uses PUT for this endpoint
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({
          currentPassword: currentPassword,
          newPassword: newPassword
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        if (response.status === 400) {
          setPasswordError(errorData.message || 'Current password is incorrect');
        } else if (response.status === 401) {
          setPasswordError('Authentication failed. Please log out and log in again.');
        } else {
          setPasswordError(errorData.message || 'Failed to change password');
        }
        return;
      }
      
      // Clear form and show success message
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setPasswordSuccess('Password changed successfully!');
      
      // Clear success message after 5 seconds
      setTimeout(() => {
        setPasswordSuccess('');
      }, 5000);
    } catch (err) {
      console.error('Error changing password:', err);
      setPasswordError('Network error. Please try again later.');
    } finally {
      setIsChangingPassword(false);
    }
  };

  // Add keyboard navigation for image gallery
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!selectedImage) return;
      
      switch (e.key) {
        case 'ArrowRight':
          handleNextImage();
          break;
        case 'ArrowLeft':
          handlePrevImage();
          break;
        case 'Escape':
          handleCloseModal();
          break;
        default:
          break;
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    
    // Clean up event listener on unmount or when selectedImage changes
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedImage, currentImageIndex]);

  // Function to fetch admin messages
  const fetchAdminMessages = async () => {
    try {
      const response = await fetch(`${API_URL}/user/messages`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        
        // Sort messages to show pinned messages at the top
        const sortedMessages = [...data].sort((a, b) => {
          // First sort by pinned status (pinned messages first)
          if (a.pinned && !b.pinned) return -1;
          if (!a.pinned && b.pinned) return 1;
          
          // Then sort by date (newest first)
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        
        setLocalAdminMessages(sortedMessages);
      } else {
        console.error('Failed to fetch admin messages');
      }
    } catch (error) {
      console.error('Error fetching admin messages:', error);
    }
  };

  // Add function to toggle message minimized state
  const toggleMessageMinimize = (messageId) => {
    if (minimizedMessages.includes(messageId)) {
      setMinimizedMessages(minimizedMessages.filter(id => id !== messageId));
    } else {
      setMinimizedMessages([...minimizedMessages, messageId]);
    }
  };

  // Add this effect to initialize spell data
  useEffect(() => {
    const initializeSpellData = async () => {
      if (characterSheet && characterSheet.items) {
        // Extract spells from character sheet
        const spells = characterSheet.items.filter(item => 
          item.type === 'spell' || 
          item.type === 'power' || 
          (item.flags && item.flags.spellbook)
        );
        
        // Initialize ability resources from character features and class abilities
        const abilities = characterSheet.items.filter(item => 
          item.type === 'feat' || 
          item.type === 'feature' ||
          item.type === 'class'
        ).map(ability => ({
          id: ability.id || ability._id,
          name: ability.name,
          description: ability.system?.description?.value || 'No description available',
          uses: {
            value: ability.system?.uses?.value || 0,
            max: ability.system?.uses?.max || 0,
            per: ability.system?.uses?.per || 'long rest'
          },
          active: false
        }));
        
        // Initialize spell slots from character sheet
        const slots = {};
        if (characterSheet.system?.spells) {
          for (let level = 1; level <= 9; level++) {
            const slotKey = `spell${level}`;
            if (characterSheet.system.spells[slotKey]) {
              slots[level] = {
                max: characterSheet.system.spells[slotKey].max || 0,
                used: characterSheet.system.spells[slotKey].max - (characterSheet.system.spells[slotKey].value || 0)
              };
            }
          }
        }
        
        setSpellLibrary(spells);
        setFilteredSpells(spells);
        setAbilityResources(abilities);
        setSpellSlots(slots);
      }
    };
    
    initializeSpellData();
  }, [characterSheet]);

  // REPLACE WITH THIS NEW CODE:

  // Add this effect to load ALL spells from JSON files and initialize data
  useEffect(() => {
    const initializeSpellData = async () => {
      try {
        console.log("Loading all spells from JSON files...");
        setIsLoadingSpells(true);
        
        // Load all spells from JSON files
        let allSpells = [];
        let sourceBooks = new Set();
        
        // Directly use the specific spell file names
        const spellFiles = [
          'spells-phb.json',
          'spells-tce.json',
          'spells-xge.json',
          'spells-aag.json',
          'spells-ai.json',
          'spells-aitfr-avt.json',
          'spells-bmt.json',
          'spells-dodk.json',
          'spells-egw.json',
          'spells-ftd.json',
          'spells-ggr.json',
          'spells-ghloe.json',
          'spells-idrotf.json',
          'spells-llk.json',
          'spells-sato.json',
          'spells-scc.json',
          'spells-tdcsr.json',
          'spells-xphb.json'
        ];
        
        // Load each spell file
        for (const filename of spellFiles) {
          try {
            console.log(`Loading ${filename}...`);
            const module = await import(`../data/spells/${filename}`);
            const source = filename.replace('spells-', '').replace('.json', '').toUpperCase();
            sourceBooks.add(source);
            
            if (module && module.spell) {
              if (Array.isArray(module.spell)) {
                // Handle arrays of spells
                const spellsWithSource = module.spell.map(spell => ({
                  ...spell,
                  source: source,
                  sourceBook: source
                }));
                allSpells = [...allSpells, ...spellsWithSource];
              } else {
                // Handle single spell objects
                allSpells.push({
                  ...module.spell,
                  source: source,
                  sourceBook: source
                });
              }
            }
          } catch (error) {
            console.error(`Error loading ${filename}:`, error);
          }
        }
        
        console.log(`Loaded ${allSpells.length} spells from ${sourceBooks.size} source books`);
        
        // Extract character abilities
        const abilities = characterSheet?.items
          ? characterSheet.items
              .filter(item => item.type === 'feat' || item.type === 'feature' || item.type === 'class')
              .map(ability => ({
                id: ability.id || ability._id,
                name: ability.name,
                class: ability.system?.class || getClassFromName(ability.name) || 'General',
                description: ability.system?.description?.value || 'No description available',
                uses: {
                  value: ability.system?.uses?.value || 0,
                  max: ability.system?.uses?.max || 0,
                  per: ability.system?.uses?.per || 'long rest'
                },
                active: false
              }))
          : [];
        
        // Initialize spell slots from character sheet
        const slots = {};
        if (characterSheet?.system?.spells) {
          for (let level = 1; level <= 9; level++) {
            const slotKey = `spell${level}`;
            if (characterSheet.system.spells[slotKey]) {
              slots[level] = {
                max: characterSheet.system.spells[slotKey].max || 0,
                used: characterSheet.system.spells[slotKey].max - (characterSheet.system.spells[slotKey].value || 0)
              };
            }
          }
        }
        
        // Update all the necessary state
        setSpellSourceBooks([...sourceBooks]);
        setActiveSpellSourceFilters([...sourceBooks]); // By default enable all sources
        setAllSpellsData(allSpells);
        setSpellLibrary(allSpells);
        setFilteredSpells(allSpells);
        setAbilityResources(abilities);
        setSpellSlots(slots);
        
        // Fetch prepared spells and favorites from the server
        fetchPreparedSpells();
        fetchFavoriteSpells();
      } catch (error) {
        console.error("Error initializing spell data:", error);
      } finally {
        setIsLoadingSpells(false);
      }
    };
    
    initializeSpellData();
  }, [characterSheet]);

  // Update the renderSpellManagerTab function to better separate spells and abilities
  const renderSpellManagerTab = () => {
  return (
      <div className="spell-manager-tab">
        <div className="spell-manager-header">
          <div className="spell-manager-title">
            <h2>
              {viewMode === 'library' && 'Spell Library'}
            </h2>
          </div>
          
          <div className="view-mode-tabs desktop-only">
            <button 
              className={`view-mode-tab active`}
              onClick={() => setViewMode('library')}
            >
              Spell Library
            </button>
          </div>
          
          {viewMode === 'library' && (
            <div className="spell-search-container">
              <input
                type="text"
                className="spell-search-input"
                placeholder="Search spells..."
                value={spellSearch}
                onChange={(e) => setSpellSearch(e.target.value)}
              />
              <div className="filter-buttons">
                <button 
                  className={`filter-button ${activeSpellFilters.favorites ? 'active' : ''}`}
                  onClick={() => setActiveSpellFilters({...activeSpellFilters, favorites: !activeSpellFilters.favorites})}
                >
                  <span role="img" aria-label="Favorites">⭐</span> Favorites
                </button>
                <button 
                  className={`filter-button ${activeSpellFilters.prepared ? 'active' : ''}`}
                  onClick={() => setActiveSpellFilters({...activeSpellFilters, prepared: !activeSpellFilters.prepared})}
                >
                  Prepared Only
                </button>
                <button 
                  className={`filter-button ${activeSpellFilters.level.length > 0 ? 'active' : ''}`}
                  onClick={() => setFilterMenuOpen('level')}
                >
                  Level
                </button>
                <button 
                  className={`filter-button ${activeSpellFilters.school.length > 0 ? 'active' : ''}`}
                  onClick={() => setFilterMenuOpen('school')}
                >
                  School
                </button>
              </div>
            </div>
          )}
        </div>
        
        <div className="spell-manager-content">
          {renderSpellLibrary()}
        </div>
      </div>
    );
  };

  // Add state to track expanded spell rows
  const [expandedSpells, setExpandedSpells] = useState({});
  const [sourceFilterMinimized, setSourceFilterMinimized] = useState(false);

  // Toggle spell expansion
  const toggleSpellExpand = (spellId) => {
    setExpandedSpells(prev => ({
      ...prev,
      [spellId]: !prev[spellId]
    }));
  };

  // Modify the renderSpellLibrary function to use a table with expandable rows
  const renderSpellLibrary = () => {
    // Check if we have any source books to filter by
    const hasSourceBooks = spellSourceBooks.length > 0;
    
    // Apply all filters to the spell list
    const displaySpells = (() => {
      // First filter by source
      let filtered = filteredSpells.filter(spell => {
        const spellSource = spell.source || spell.sourceBook || 'Unknown';
        return activeSpellSourceFilters.includes(spellSource) || activeSpellSourceFilters.length === 0;
      });
      
      // Then filter by search term
      if (spellSearch) {
        const searchLower = spellSearch.toLowerCase();
        filtered = filtered.filter(spell => 
          spell.name.toLowerCase().includes(searchLower)
        );
      }
      
      // Filter by level if any level filters are active
      if (activeSpellFilters.level.length > 0) {
        filtered = filtered.filter(spell => {
          const level = parseInt(spell.level || spell.system?.level || 0);
          return activeSpellFilters.level.includes(level);
        });
      }
      
      // Filter by school if any school filters are active
      if (activeSpellFilters.school.length > 0) {
        filtered = filtered.filter(spell => {
          const school = spell.school || spell.system?.school || 'Unknown';
          return activeSpellFilters.school.includes(school);
        });
      }
      
      // Filter by prepared status if the prepared filter is active
      if (activeSpellFilters.prepared) {
        filtered = filtered.filter(spell => isSpellPrepared(spell));
      }
      
      // Filter by favorites if the favorites filter is active
      if (activeSpellFilters.favorites) {
        filtered = filtered.filter(spell => isSpellFavorite(spell));
      }
      
      return filtered;
    })();
    
    // Sort spells by level, then name
    const sortedSpells = [...displaySpells].sort((a, b) => {
      const levelA = parseInt(a.level || a.system?.level || 0);
      const levelB = parseInt(b.level || b.system?.level || 0);
      if (levelA !== levelB) return levelA - levelB;
      return a.name.localeCompare(b.name);
    });

    // Generate a unique ID for each spell
    const getSpellId = (spell) => {
      return spell.id || `${spell.name}-${spell.source || spell.sourceBook || 'unknown'}`.replace(/\s+/g, '-').toLowerCase();
    };

    // Format casting time display
    const formatCastingTime = (spell) => {
      if (spell.time && spell.time.length > 0) {
        return `${spell.time[0]?.number || 1} ${spell.time[0]?.unit || 'action'}`;
      }
      return spell.system?.activation?.type || 'Action';
    };

    // Format range display
    const formatRange = (spell) => {
      if (spell.range) {
        return `${spell.range.distance?.amount || ''} ${spell.range.distance?.type || ''}`;
      }
      return spell.system?.range?.value || 'Self';
    };
    
    return (
      <div className="spell-library-container">
        {isLoadingSpells ? (
          <div className="loading-spells">Loading spells library...</div>
        ) : (
          <>
            {/* Add source book filtering section */}
            {hasSourceBooks && (
              <div className="source-filters">
                <div className="filter-header">
                  <h4>Source Books</h4>
                  <div className="filter-actions">
                    <button 
                      className="select-all-btn"
                      onClick={() => setActiveSpellSourceFilters(spellSourceBooks)}
                    >
                      Select All
                    </button>
                    <button 
                      className="clear-all-btn"
                      onClick={() => setActiveSpellSourceFilters([])}
                    >
                      Clear All
                    </button>
                    <button
                      className="minimize-filter-btn"
                      onClick={() => setSourceFilterMinimized(!sourceFilterMinimized)}
                      title={sourceFilterMinimized ? "Expand Source Books" : "Collapse Source Books"}
                    >
                      {sourceFilterMinimized ? "+" : "−"}
                    </button>
                  </div>
                </div>
                {!sourceFilterMinimized && (
                  <div className="source-filter-list">
                    {spellSourceBooks.map(source => (
                      <button 
                        key={source}
                        className={`source-filter-btn ${activeSpellSourceFilters.includes(source) ? 'active' : ''}`}
                        onClick={() => {
                          setActiveSpellSourceFilters(prev => 
                            prev.includes(source) 
                              ? prev.filter(s => s !== source) 
                              : [...prev, source]
                          );
                        }}
                      >
                        {source}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            )}
            
            {/* Filter menu for level */}
            {filterMenuOpen === 'level' && (
              <div className="filter-menu">
                <div className="filter-menu-header">
                  <h4>Filter by Level</h4>
                  <button 
                    className="close-filter-menu"
                    onClick={() => setFilterMenuOpen(null)}
                  >
                    ×
                  </button>
                </div>
                <div className="filter-options">
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(level => (
                    <label key={level} className="filter-option">
                      <input 
                        type="checkbox"
                        checked={activeSpellFilters.level.includes(level)}
                        onChange={() => {
                          setActiveSpellFilters(prev => {
                            const newLevels = prev.level.includes(level)
                              ? prev.level.filter(l => l !== level)
                              : [...prev.level, level];
                            return { ...prev, level: newLevels };
                          });
                        }}
                      />
                      {level === 0 ? 'Cantrip' : `Level ${level}`}
                    </label>
                  ))}
                </div>
              </div>
            )}
            
            {/* Filter menu for school */}
            {filterMenuOpen === 'school' && (
              <div className="filter-menu">
                <div className="filter-menu-header">
                  <h4>Filter by School</h4>
                  <button 
                    className="close-filter-menu"
                    onClick={() => setFilterMenuOpen(null)}
                  >
                    ×
                  </button>
                </div>
                <div className="filter-options">
                  {['Abjuration', 'Conjuration', 'Divination', 'Enchantment', 'Evocation', 'Illusion', 'Necromancy', 'Transmutation'].map(school => (
                    <label key={school} className="filter-option">
                      <input 
                        type="checkbox"
                        checked={activeSpellFilters.school.includes(school)}
                        onChange={() => {
                          setActiveSpellFilters(prev => {
                            const newSchools = prev.school.includes(school)
                              ? prev.school.filter(s => s !== school)
                              : [...prev.school, school];
                            return { ...prev, school: newSchools };
                          });
                        }}
                      />
                      {school}
                    </label>
                  ))}
                </div>
              </div>
            )}
            
            {/* Spell table list with expandable rows */}
            <div className="spell-table-container">
              {displaySpells.length === 0 ? (
                <div className="no-spells-message">
                  No spells found matching your criteria.
                </div>
              ) : (
                <table className="spell-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th className="spell-level-col">Level</th>
                      <th className="spell-school-col">School</th>
                      <th className="spell-time-col">Time</th>
                      <th className="spell-range-col">Range</th>
                      <th className="spell-source-col">Source</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedSpells.map(spell => {
                      const spellId = getSpellId(spell);
                      const isExpanded = expandedSpells[spellId] || false;
                      return (
                        <React.Fragment key={spellId}>
                          <tr 
                            className="spell-row"
                            onClick={() => toggleSpellExpand(spellId)}
                          >
                            <td>
                              <button 
                                className={`expand-button ${isExpanded ? 'expanded' : ''}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleSpellExpand(spellId);
                                }}
                                aria-label={isExpanded ? "Collapse spell details" : "Expand spell details"}
                              >
                                +
                              </button>
                            </td>
                            <td>{spell.name}</td>
                            <td className="spell-level-col">
                              <span className="spell-level-display">
                                {(spell.level === 0 || spell.system?.level === 0) ? 'C' : spell.level || spell.system?.level || 0}
                              </span>
                            </td>
                            <td className="spell-school-col">{(spell.school || spell.system?.school || 'Unknown').substring(0, 3)}</td>
                            <td className="spell-time-col">{formatCastingTime(spell)}</td>
                            <td className="spell-range-col">{formatRange(spell)}</td>
                            <td className="spell-source-col">
                              <span className="spell-source-tag">
                                {spell.source || spell.sourceBook || 'Unknown'}
                              </span>
                            </td>
                          </tr>
                          
                          {isExpanded && (
                            <tr className="spell-detail-row">
                              <td colSpan="7">
                                <div className="spell-detail-container">
                                  <div className="spell-detail-header">
                                    <h3 className="spell-detail-title">{spell.name}</h3>
                                    <div className="spell-detail-actions">
                                      <button 
                                        className={`prepare-spell-btn ${isSpellPrepared(spell) ? 'prepared' : ''}`}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          console.log('=== PREPARE BUTTON CLICKED ===');
                                          console.log('Spell:', spell);
                                          console.log('Is already prepared:', isSpellPrepared(spell));
                                          console.log('Current prepared spells count:', preparedSpells.length);
                                          handlePrepareSpell(spell);
                                        }}
                                      >
                                        {isSpellPrepared(spell) ? 'Unprepare' : 'Prepare'}
                                      </button>
                                      <button 
                                        className="add-to-favorites-btn"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleAddToFavorites(spell);
                                        }}
                                      >
                                        {isSpellFavorite(spell) ? 'Remove' : 'Favorite'}
                                      </button>
                                    </div>
                                  </div>
                                  
                                  <div className="spell-meta-info">
                                    <div className="spell-meta-item">
                                      <span className="spell-meta-label">Level</span>
                                      <span className="spell-meta-value">
                                        {(spell.level === 0 || spell.system?.level === 0) 
                                          ? 'Cantrip' 
                                          : `${spell.level || spell.system?.level || 0}`
                                        }
                                      </span>
                                    </div>
                                    <div className="spell-meta-item">
                                      <span className="spell-meta-label">School</span>
                                      <span className="spell-meta-value">
                                        {spell.school || spell.system?.school || 'Unknown'}
                                      </span>
                                    </div>
                                    <div className="spell-meta-item">
                                      <span className="spell-meta-label">Time</span>
                                      <span className="spell-meta-value">
                                        {formatCastingTime(spell)}
                                      </span>
                                    </div>
                                    <div className="spell-meta-item">
                                      <span className="spell-meta-label">Range</span>
                                      <span className="spell-meta-value">
                                        {formatRange(spell)}
                                      </span>
                                    </div>
                                    <div className="spell-meta-item">
                                      <span className="spell-meta-label">Components</span>
                                      <span className="spell-meta-value">
                                        {spell.components
                                          ? `${spell.components.v ? 'V' : ''}${spell.components.s ? 'S' : ''}${spell.components.m ? 'M' : ''}`
                                          : (spell.system?.components?.verbal ? 'V' : '') + 
                                            (spell.system?.components?.somatic ? 'S' : '') + 
                                            (spell.system?.components?.material ? 'M' : '')
                                        }
                                      </span>
                                    </div>
                                    <div className="spell-meta-item">
                                      <span className="spell-meta-label">Duration</span>
                                      <span className="spell-meta-value">
                                        {spell.duration
                                          ? spell.duration[0]?.type === 'instant' 
                                            ? 'Instantaneous' 
                                            : `${spell.duration[0]?.duration?.amount || ''} ${spell.duration[0]?.duration?.type || ''}`
                                          : spell.system?.duration?.value || 'Instantaneous'
                                        }
                                      </span>
                                    </div>
                                    <div className="spell-meta-item">
                                      <span className="spell-meta-label">Source</span>
                                      <span className="spell-meta-value">
                                        {spell.source || spell.sourceBook || 'Unknown'}
                                      </span>
                                    </div>
                                  </div>
                                  
                                  <div className="spell-description-container">
                                    <div className="spell-description">
                                      {spell.entries 
                                        ? spell.entries.map(entry => 
                                            typeof entry === 'string' 
                                              ? entry 
                                              : JSON.stringify(entry, null, 2)
                                          ).join('\n\n')
                                        : spell.system?.description?.value 
                                          ? spell.system.description.value.replace(/<\/?[^>]+(>|$)/g, "") 
                                          : 'No description available'
                                      }
                                      
                                      {spell.entriesHigherLevel && (
                                        <div className="spell-higher-levels">
                                          <strong>At Higher Levels:</strong> {' '}
                                          {spell.entriesHigherLevel.map(entry => 
                                            typeof entry === 'string' 
                                              ? entry 
                                              : entry.entries.join('\n')
                                          ).join('\n')}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  // Add these functions before renderSpellManagerTab 

  // Helper function to check if a spell is in favorites
  const isSpellFavorite = (spell) => {
    // Generate a unique identifier for the spell
    const spellId = spell.id || spell._id || `${spell.name}-${spell.source || spell.sourceBook}`;
    
    // Check if this spell is in favorites
    return favoriteSpells.some(favSpell => {
      const favId = favSpell.id || favSpell._id || `${favSpell.name}-${favSpell.source || favSpell.sourceBook}`;
      return favId === spellId;
    });
  };

  // Function to add or remove a spell from favorites
  const handleAddToFavorites = async (spell) => {
    // Generate a unique identifier for the spell
    const spellId = spell.id || spell._id || `${spell.name}-${spell.source || spell.sourceBook}`;
    console.log('Adding/removing spell from favorites:', spell.name, 'with ID:', spellId);
    
    // Update the local state for immediate feedback
    let newFavoriteSpells;
    if (isSpellFavorite(spell)) {
      console.log('Removing spell from favorites');
      // Remove from favorites
      newFavoriteSpells = favoriteSpells.filter(favSpell => {
        const favId = favSpell.id || favSpell._id || `${favSpell.name}-${favSpell.source || favSpell.sourceBook}`;
        return favId !== spellId;
      });
    } else {
      console.log('Adding spell to favorites');
      // Add to favorites
      newFavoriteSpells = [...favoriteSpells, spell];
    }
    
    // Update the UI immediately
    setFavoriteSpells(newFavoriteSpells);
    
    try {
      // Send the updated favorite spells to the server
      console.log('Sending favorite spells to server...');
      const response = await fetch(`${API_URL}/character-data/spell-favorites`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify(newFavoriteSpells)
      });
      
      if (!response.ok) {
        console.error('Server responded with status:', response.status);
        throw new Error(`Failed to update favorite spells: ${response.statusText}`);
      }
      
      console.log('Favorite spells saved successfully to user profile');
      
    } catch (error) {
      console.error('Error saving favorite spells:', error);
      alert(`Failed to save favorite spells: ${error.message}`);
    }
  };

  // Function to check if a spell is prepared
  const isSpellPrepared = (spell) => {
    // Generate a unique identifier for the spell
    const spellId = spell.id || spell._id || `${spell.name}-${spell.source || spell.sourceBook}`;
    
    // Check if this spell is in prepared spells
    return preparedSpells.some(prepSpell => {
      const prepId = prepSpell.id || prepSpell._id || `${prepSpell.name}-${prepSpell.source || prepSpell.sourceBook}`;
      return prepId === spellId;
    });
  };
  
  // Function to prepare or unprepare a spell
  const handlePrepareSpell = async (spell) => {
    // Generate a unique identifier for the spell
    const spellId = spell.id || spell._id || `${spell.name}-${spell.source || spell.sourceBook}`;
    console.log('Preparing/unpreparing spell:', spell.name, 'with ID:', spellId);
    
    // Update the local state for immediate feedback
    let newPreparedSpells;
    if (isSpellPrepared(spell)) {
      console.log('Removing spell from prepared spells');
      // Remove from prepared spells
      newPreparedSpells = preparedSpells.filter(prepSpell => {
        const prepId = prepSpell.id || prepSpell._id || `${prepSpell.name}-${prepSpell.source || prepSpell.sourceBook}`;
        return prepId !== spellId;
      });
    } else {
      console.log('Adding spell to prepared spells');
      // Add to prepared spells
      newPreparedSpells = [...preparedSpells, spell];
    }
    
    // Update the UI immediately
    setPreparedSpells(newPreparedSpells);
    
    try {
      // Send the updated prepared spells to the server
      console.log('Sending prepared spells to server...');
      const response = await fetch(`${API_URL}/character-data/prepared-spells`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify(newPreparedSpells)
      });
      
      if (!response.ok) {
        console.error('Server responded with status:', response.status);
        throw new Error(`Failed to update prepared spells: ${response.statusText}`);
      }
      
      console.log('Prepared spells saved successfully to user profile');
      
    } catch (error) {
      console.error('Error saving prepared spells:', error);
      alert(`Failed to save prepared spells: ${error.message}`);
    }
  };
  
  // Function to load prepared spells from the server
  const fetchPreparedSpells = async () => {
    try {
      console.log('Fetching prepared spells from server');
      const response = await fetch(`${API_URL}/character-data/prepared-spells`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        console.error('Server responded with status:', response.status);
        throw new Error(`Failed to fetch prepared spells: ${response.statusText}`);
      }
      
      const data = await response.json();
      console.log('Prepared spells loaded from user profile:', data?.length || 0, 'spells');
      
      if (Array.isArray(data)) {
        setPreparedSpells(data);
      } else {
        console.warn('Server returned invalid data format', data);
        setPreparedSpells([]);
      }
    } catch (error) {
      console.error('Error fetching prepared spells:', error);
      setPreparedSpells([]);
    }
  };

  // Add effect to fetch prepared spells when component mounts
  useEffect(() => {
    if (isMounted) {
      console.log('Initiating fetch of prepared spells on component mount');
      fetchPreparedSpells();
    }
  }, [isMounted]);

  // Function to render the spell slot tracker
  const renderSpellSlotTracker = () => {
    const handleSlotChange = (level, index, used) => {
      // Clone the current spell slots state
      const newSlots = {...spellSlots};
      
      // Update the used count
      if (used) {
        // Mark as used
        if (newSlots[level].used < newSlots[level].max) {
          newSlots[level].used += 1;
        }
      } else {
        // Mark as available
        if (newSlots[level].used > 0) {
          newSlots[level].used -= 1;
        }
      }
      
      setSpellSlots(newSlots);
    };
    
    const handleRestClick = (restType) => {
      // Clone the current spell slots state
      const newSlots = {...spellSlots};
      
      // Reset spell slots based on rest type
      if (restType === 'long') {
        // Reset all spell slots on long rest
        Object.keys(newSlots).forEach(level => {
          newSlots[level].used = 0;
        });
      } else if (restType === 'short') {
        // For short rest, reset only certain slots based on character class
        // This would be more complex and depends on character class features
        // For now, we'll just implement a simple version
        if (characterSheet && characterSheet.system?.details?.class?.toLowerCase() === 'warlock') {
          Object.keys(newSlots).forEach(level => {
            newSlots[level].used = 0;
          });
        }
      }
      
      setSpellSlots(newSlots);
    };
    
    const handleClassChange = (e) => {
      setSpellCasterClass(e.target.value);
    };
    
    const handleLevelChange = (e) => {
      setCharacterLevel(e.target.value);
    };
    
    // Group prepared spells by level
    const preparedSpellsByLevel = preparedSpells.reduce((acc, spell) => {
      const level = spell.level || spell.system?.level || 0;
      if (!acc[level]) acc[level] = [];
      acc[level].push(spell);
      return acc;
    }, {});
    
    return (
      <div className="spell-slot-tracker">
        <div className="slot-tracker-header">
          <h3>Spell Slots</h3>
          
          <div className="class-level-selectors">
            <select 
              className="class-selector" 
              value={spellCasterClass} 
              onChange={handleClassChange}
            >
              <option value="">Class</option>
              <option value="artificer">Artificer</option>
              <option value="bard">Bard</option>
              <option value="cleric">Cleric</option>
              <option value="druid">Druid</option>
              <option value="paladin">Paladin</option>
              <option value="ranger">Ranger</option>
              <option value="sorcerer">Sorcerer</option>
              <option value="warlock">Warlock</option>
              <option value="wizard">Wizard</option>
            </select>
            
            <select 
              className="level-selector" 
              value={characterLevel} 
              onChange={handleLevelChange}
            >
              <option value="">Level</option>
              {[...Array(20)].map((_, i) => (
                <option key={i+1} value={i+1}>{i+1}</option>
              ))}
            </select>
          </div>
          
          <div className="rest-buttons">
            <button className="short-rest-btn" onClick={() => handleRestClick('short')}>
              Short Rest
            </button>
            <button className="long-rest-btn" onClick={() => handleRestClick('long')}>
              Long Rest
            </button>
          </div>
        </div>
        
        <div className="spell-slot-levels">
          {/* Cantrips section */}
          <div className="spell-slot-level-row">
            <div className="level-label">Cantrips</div>
            {preparedSpellsByLevel[0] && preparedSpellsByLevel[0].length > 0 && (
              <div className="prepared-spells">
                {preparedSpellsByLevel[0].map(spell => (
                  <div key={spell.id || `${spell.name}-${spell.source}`} className="prepared-spell">
                    {spell.name}
                  </div>
                ))}
              </div>
            )}
          </div>
          
          {Object.keys(spellSlots).length === 0 ? (
            <div className="no-spell-slots">No spell slots available for this character.</div>
          ) : (
            Object.keys(spellSlots).map(level => (
              <div key={level} className="spell-slot-level-row">
                <div className="level-label">Level {level}</div>
                <div className="slot-counters">
                  {Array.from({ length: spellSlots[level].max }, (_, i) => (
                    <button 
                      key={i}
                      className={`slot-counter ${i < spellSlots[level].used ? 'used' : 'available'}`}
                      onClick={() => handleSlotChange(level, i, i >= spellSlots[level].used)}
                    >
                      {i < spellSlots[level].used ? '✓' : ''}
                    </button>
                  ))}
                </div>
                <div className="slot-fraction">
                  {spellSlots[level].max - spellSlots[level].used}/{spellSlots[level].max}
                </div>
                {preparedSpellsByLevel[level] && preparedSpellsByLevel[level].length > 0 && (
                  <div className="prepared-spells">
                    {preparedSpellsByLevel[level].map(spell => (
                      <div key={spell.id || `${spell.name}-${spell.source}`} className="prepared-spell">
                        {spell.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    );
  };

  // Function to render ability tracker
  const renderAbilityTracker = () => {
    // Group abilities by class
    const abilitiesByClass = abilityResources.reduce((acc, ability) => {
      const className = ability.class || 'General';
      if (!acc[className]) acc[className] = [];
      acc[className].push(ability);
      return acc;
    }, {});
    
    // Get classes for filter tabs
    const classes = Object.keys(abilitiesByClass);
    
    // Filter abilities based on active class
    const filteredAbilities = activeClassFilter === 'All' 
      ? abilityResources 
      : abilitiesByClass[activeClassFilter] || [];
    
    const toggleAbilityUsed = (abilityId) => {
      setAbilityResources(abilities => 
        abilities.map(ability => 
          ability.id === abilityId 
            ? { ...ability, active: !ability.active } 
            : ability
        )
      );
    };
    
    const handleUseAbility = (abilityId) => {
      setAbilityResources(abilities => 
        abilities.map(ability => {
          if (ability.id === abilityId && ability.uses.value > 0) {
            return { 
              ...ability, 
              uses: { 
                ...ability.uses, 
                value: ability.uses.value - 1 
              } 
            };
          }
          return ability;
        })
      );
    };
    
    const handleRestoreAbility = (abilityId) => {
      setAbilityResources(abilities => 
        abilities.map(ability => {
          if (ability.id === abilityId) {
            return { 
              ...ability, 
              uses: { 
                ...ability.uses, 
                value: ability.uses.max 
              } 
            };
          }
          return ability;
        })
      );
    };
    
    const restAllAbilities = (restType) => {
      setAbilityResources(abilities => 
        abilities.map(ability => {
          if (restType === 'long' || (restType === 'short' && ability.uses.per === 'short rest')) {
            return { 
              ...ability, 
              uses: { 
                ...ability.uses, 
                value: ability.uses.max 
              },
              active: false
            };
          }
          return ability;
        })
      );
    };
    
    // Helper function to assign colors to different classes for the ability indicators
    const getClassColor = (className) => {
      const classColors = {
        'Barbarian': '#e74c3c',
        'Bard': '#9b59b6',
        'Cleric': '#f1c40f',
        'Druid': '#27ae60',
        'Fighter': '#c0392b',
        'Monk': '#3498db',
        'Paladin': '#f39c12',
        'Ranger': '#2ecc71',
        'Rogue': '#34495e',
        'Sorcerer': '#e67e22',
        'Warlock': '#8e44ad',
        'Wizard': '#2980b9',
        'General': '#7f8c8d'
      };
      
      return classColors[className] || classColors.General;
    };
    
    return (
      <div className="ability-cooldown-tracker">
        <div className="ability-tracker-header">
          <h3>Class Abilities & Features</h3>
          <div className="rest-buttons">
            <button className="short-rest-btn" onClick={() => restAllAbilities('short')}>
              Short Rest
            </button>
            <button className="long-rest-btn" onClick={() => restAllAbilities('long')}>
              Long Rest
            </button>
          </div>
        </div>
        
        <div className="ability-filter-tabs">
          <button 
            className={`filter-tab ${activeClassFilter === 'All' ? 'active' : ''}`}
            onClick={() => setActiveClassFilter('All')}
          >
            All
          </button>
          {classes.map(className => (
            <button 
              key={className}
              className={`filter-tab ${activeClassFilter === className ? 'active' : ''}`}
              onClick={() => setActiveClassFilter(className)}
            >
              {className}
            </button>
          ))}
        </div>
        
        <div className="ability-list">
          {filteredAbilities.length === 0 ? (
            <div className="no-abilities-message">No abilities found for this class.</div>
          ) : (
            filteredAbilities.map(ability => (
              <div key={ability.id} className="ability-item">
                <div className="ability-info">
                  <div className="ability-name">
                    <span className="ability-class-indicator" style={{ 
                      backgroundColor: getClassColor(ability.class || 'General') 
                    }}></span>
                    {ability.name}
                  </div>
                  <div className="ability-description">
                    <pre className="ability-code-block"><code>
                      {ability.description ? ability.description.replace(/<\/?[^>]+(>|$)/g, "") : 'No description available'}
                    </code></pre>
                  </div>
                  <div className="ability-recharge">
                    Recharges on {ability.uses.per}
                  </div>
                </div>
                
                <div className="ability-usage">
                  {ability.uses.max > 0 ? (
                    <>
                      <div className="usage-counters">
                        <span className="usage-fraction">{ability.uses.value}/{ability.uses.max}</span>
                      </div>
                      <div className="ability-actions">
                        <button 
                          className="use-ability-btn"
                          onClick={() => handleUseAbility(ability.id)}
                          disabled={ability.uses.value <= 0}
                        >
                          Use Ability
                        </button>
                        <button 
                          className="restore-ability-btn"
                          onClick={() => handleRestoreAbility(ability.id)}
                        >
                          Restore Use
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="toggle-ability">
                      <label className="toggle-switch">
                        <input 
                          type="checkbox" 
                          checked={ability.active}
                          onChange={() => toggleAbilityUsed(ability.id)}
                        />
                        <span className="toggle-slider"></span>
                      </label>
                      <span className="toggle-label">{ability.active ? 'Active' : 'Inactive'}</span>
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    );
  };

  // Function to render favorites section
  // No longer needed as favorites are now integrated into the spell library with a filter

  // Helper function to extract class information from ability names
  const getClassFromName = (abilityName) => {
    if (!abilityName) return 'General';
    
    const abilityNameLower = abilityName.toLowerCase();
    
    // Common class prefixes in ability names
    const classPatterns = {
      'barbarian': ['barbarian', 'rage', 'primal ', 'totem ', 'berserker'],
      'bard': ['bard', 'bardic', 'college of', 'song of'],
      'cleric': ['cleric', 'divine', 'channel divinity', 'domain', 'turn undead'],
      'druid': ['druid', 'wild shape', 'circle of', 'natural recovery'],
      'fighter': ['fighter', 'action surge', 'second wind', 'combat superiority', 'eldritch knight'],
      'monk': ['monk', 'ki ', 'monastic', 'way of', 'flurry of blows', 'martial arts'],
      'paladin': ['paladin', 'divine sense', 'divine smite', 'lay on hands', 'sacred oath'],
      'ranger': ['ranger', 'favored enemy', 'natural explorer', 'hunter\'s', 'primeval awareness'],
      'rogue': ['rogue', 'sneak attack', 'cunning action', 'uncanny dodge', 'thieves\''],
      'sorcerer': ['sorcerer', 'sorcery', 'metamagic', 'sorcerous origin', 'wild magic', 'draconic'],
      'warlock': ['warlock', 'eldritch', 'pact of', 'patron', 'invocation'],
      'wizard': ['wizard', 'arcane', 'spell mastery', 'arcane recovery', 'arcane tradition', 'spellbook']
    };
    
    // Check if the ability name contains any class patterns
    for (const [className, patterns] of Object.entries(classPatterns)) {
      for (const pattern of patterns) {
        if (abilityNameLower.includes(pattern)) {
          return className.charAt(0).toUpperCase() + className.slice(1); // Capitalize the first letter
        }
      }
    }
    
    // If no match is found for specific class patterns, check for broader indicators
    if (abilityNameLower.includes('spell') || abilityNameLower.includes('cast') || 
        abilityNameLower.includes('magic')) {
      return 'Spellcaster';
    }
    
    return 'General';
  };

  // Update the useEffect - we don't need to modify body scrolling for expandable rows
  useEffect(() => {
    // No need to modify body scroll with the expandable list approach
    document.body.style.overflow = '';
    
    return () => {
      document.body.style.overflow = '';
    };
  }, []);
  
  // Replace the backdrop and slide panel with empty fragments
  // The remaining component code will stay the same

  // Add mobile menu state at the top with other state variables
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Update the horizontal menu in mobile view
  const MobileNavigation = () => {
    return (
      <div className={`mobile-menu ${mobileMenuOpen ? 'open' : ''}`}>
        <div className="mobile-menu-content">
          <div className="mobile-menu-header">
            <button 
              className="close-mobile-menu" 
              onClick={() => setMobileMenuOpen(false)}
            >
              ×
            </button>
          </div>
          
          <div className="mobile-menu-sections">
            <div className="mobile-menu-section">
              <h4>Dashboard</h4>
              <div className="mobile-menu-items">
                <button 
                  className={`mobile-menu-item ${activeTab === 'overview' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveTab('overview');
                    setMobileMenuOpen(false);
                  }}
                >
                  Messages
                </button>
                <button 
                  className={`mobile-menu-item ${activeTab === 'character' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveTab('character');
                    setMobileMenuOpen(false);
                  }}
                >
                  Character
                </button>
                <button 
                  className={`mobile-menu-item ${activeTab === 'spellManager' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveTab('spellManager');
                    setMobileMenuOpen(false);
                  }}
                >
                  L.I.B.R.I.S.
                </button>
                <button 
                  className={`mobile-menu-item ${activeTab === 'gallery' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveTab('gallery');
                    setMobileMenuOpen(false);
                  }}
                >
                  Image Gallery
                </button>
                <button 
                  className={`mobile-menu-item ${activeTab === 'account' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveTab('account');
                    setMobileMenuOpen(false);
                  }}
                >
                  Account
                </button>
              </div>
            </div>
            
            {activeTab === 'spellManager' && (
              <div className="mobile-menu-section">
                <h4>Spell Manager</h4>
                <div className="mobile-menu-items">
                  <button 
                    className={`mobile-menu-item ${viewMode === 'library' ? 'active' : ''}`}
                    onClick={() => {
                      setViewMode('library');
                      setMobileMenuOpen(false);
                    }}
                  >
                    Spell Library
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  // Add these functions to handle tab rendering
  const renderOverviewTab = () => {
    return (
          <div className="overview-tab">
            <div className="dashboard-sections">
              <div className="gm-messages">
                <h4>GM Messages</h4>
                {localAdminMessages && localAdminMessages.length > 0 ? (
                  <div className="message-list">
                    {localAdminMessages
                      .sort((a, b) => {
                        if (a.pinned && !b.pinned) return -1;
                        if (!a.pinned && b.pinned) return 1;
                        return new Date(b.createdAt) - new Date(a.createdAt);
                      })
                      .map((message, index) => (
                        <div key={message.id || index}>
                          <div className="message-header">
                            <div className="message-from">
                              <span className="message-subject">
                                {message.subject || 'No Subject'}
                              </span>
                              {message.pinned && <span className="pinned-badge small">PINNED</span>}
                            </div>
                            <div className="message-date">
                              {formatDate(message.createdAt)}
                              <button 
                                className="minimize-button"
                                onClick={() => toggleMessageMinimize(message.id || index)}
                              >
                                {minimizedMessages.includes(message.id || index) ? '+' : '−'}
                              </button>
                            </div>
                          </div>
                          
                          {!minimizedMessages.includes(message.id || index) && (
                            <iframe
                              src={createIframeSrc(message.content)} 
                              title={message.subject || 'GM Message'}
                              frameBorder="0"
                              scrolling="auto"
                              style={{
                                width: '100%',
                                height: 'auto',
                                minHeight: '100vh', 
                                border: 'none',
                                padding: 0,
                                margin: 0,
                                boxShadow: 'none',
                                background: 'transparent'
                              }}
                            />
                          )}
                        </div>
                      ))}
                  </div>
                ) : (
                  <div className="no-messages">No messages from the Game Master yet.</div>
                )}
              </div>
            </div>
          </div>
    );
  };

  const renderCharacterTab = () => {
    return renderCharacterSheet();
  };

  const renderGalleryTab = () => {
    return (
          <div className="gallery-tab">
            {isLoadingGallery ? (
              <div className="loading-gallery">
                <p>Loading gallery...</p>
              </div>
            ) : galleryError ? (
              <div className="gallery-error">
                <p>{galleryError}</p>
                <button onClick={currentFolder ? () => fetchFolderImages(currentFolder.category, currentFolder.path) : fetchGalleryCategories} className="retry-button">
                  Retry
                </button>
              </div>
        ) : selectedImage ? (
          // Image modal view
          <div className="image-modal">
            <div className="modal-overlay" onClick={handleCloseModal}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <img src={selectedImage.url} alt={selectedImage.name} className="full-size-image" />
                
                <div className="modal-controls">
                  <button className="modal-prev" onClick={handlePrevImage}>
                    &#10094;
                  </button>
                  <button className="modal-close" onClick={handleCloseModal}>
                    &times;
                  </button>
                  <button className="modal-next" onClick={handleNextImage}>
                    &#10095;
                  </button>
                </div>
                
                <div className="modal-info">
                  <div className="image-name">{selectedImage.name || 'Image'}</div>
                  <div className="image-details">
                    {currentImageIndex + 1} of {galleryImages.length}
                  </div>
                </div>
              </div>
            </div>
              </div>
            ) : currentFolder ? (
          // Folder view
          <div className="gallery-folder-view">
            <div className="folder-header">
              <button onClick={handleBackToFolders} className="back-button">
                ← Back to Categories
                  </button>
              <h3>{currentFolder.name || 'Gallery Folder'}</h3>
                </div>
                
            {folderImages.length === 0 ? (
                  <div className="no-images">
                    <p>No images found in this folder.</p>
                  </div>
                ) : (
                  <div className="gallery-grid">
                {folderImages.map((image, index) => (
                        <div 
                    key={index} 
                          className="gallery-item"
                          onClick={() => handleImageClick(image)}
                        >
                    <div className="gallery-image-container">
                      <img src={image.thumbnail || image.url} alt={image.name} className="gallery-thumbnail" />
                        </div>
                    <div className="gallery-image-name">{image.name}</div>
                  </div>
                ))}
                  </div>
                )}
          </div>
        ) : (
          // Categories view
          <div className="gallery-categories-view">
            <h3>Image Gallery</h3>
            {galleryCategories.length === 0 ? (
              <div className="no-categories">
                <p>No gallery categories found.</p>
                </div>
              ) : (
              <div className="categories-grid">
                {galleryCategories.map((category, index) => (
                  <div key={index} className="category-section">
                    <h4 className="category-name">{category.name}</h4>
                    
                    <div className="category-folders">
                      {category.folders && category.folders.length > 0 ? (
                        category.folders.map((folder, folderIndex) => (
                          <div 
                            key={folderIndex}
                                className="folder-item"
                            onClick={() => handleFolderClick(category.path || category.name, folder)}
                              >
                                <div className="folder-icon">
                                    <i className="folder-symbol">📁</i>
                                </div>
                            <div className="folder-name">{folder.name}</div>
                          </div>
                        ))
                      ) : (
                        <div className="no-folders">No folders in this category.</div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
            )}
              </div>
            )}
          </div>
    );
  };

  const renderAccountTab = () => {
    return (
      <div className="account-tab">
            <div className="avatar-section">
              <div className="current-avatar">
                {avatarUrl ? (
                  <img src={avatarUrl} alt="Your avatar" />
                ) : (
                  <div className="avatar-placeholder">
                    <span>{currentUser?.displayName?.[0] || currentUser?.email?.[0] || '?'}</span>
                  </div>
                )}
              </div>
              
              <div className="avatar-upload">
                <h4>Update Avatar</h4>
                <p>Upload a new avatar image (max 2MB).</p>
                
                <label className="upload-button">
                  Choose Image
                  <input 
                    type="file" 
                    accept="image/*" 
                    onChange={handleAvatarUpload} 
                    disabled={isUploading}
                  />
                </label>
                
                {isUploading && <div className="loading-spinner"></div>}
                {uploadError && <div className="upload-error">{uploadError}</div>}
              </div>
            </div>
            
            <div className="profile-info">
              <div className="info-item">
                <div className="info-label">Name</div>
                <div className="info-value">{currentUser?.displayName || 'Not set'}</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Email</div>
                <div className="info-value">{currentUser?.email}</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Role</div>
                <div className="info-value role-adventurer">Adventurer</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Member Since</div>
                <div className="info-value">{formatDate(currentUser?.createdAt)}</div>
              </div>
            </div>
            
            <div className="password-section">
              <h4>Change Password</h4>
              <form onSubmit={handlePasswordChange} className="password-form">
                {passwordError && <div className="password-error">{passwordError}</div>}
                {passwordSuccess && <div className="password-success">{passwordSuccess}</div>}
                
                <div className="password-field">
                  <label>Current Password</label>
                  <input
                    type="password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    required
                  />
                </div>
                
                <div className="password-field">
                  <label>New Password</label>
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    minLength={8}
                  />
                </div>
                
                <div className="password-field">
                  <label>Confirm New Password</label>
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    minLength={8}
                  />
                </div>
                
                <button 
                  type="submit" 
                  className="change-password-btn"
                  disabled={isChangingPassword}
                >
                  {isChangingPassword ? 'Changing Password...' : 'Change Password'}
                </button>
              </form>
            </div>
        
        <div className="links-section">
          <h4>Important Links</h4>
            <div className="links-container">
            {/* DO NOT REMOVE THESE LINKS! THEY ARE REQUIRED! */}
              <div className="link-card">
                <a
                  href="https://discord.gg/SGMCCwsd"
                  className="link-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Join Discord
                </a>
              </div>
              
              <div className="link-card">
                <a
                  href="http://47.7.21.116:30000/join"
                  className="link-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Launch Foundry
                </a>
              </div>
            </div>
          </div>
      </div>
    )
  };

  // Replace renderSpellSlotTracker with renderPreparedSpells for full spell cards
  const renderPreparedSpells = () => {
    return null; // This is a placeholder that returns nothing
  };
  
  // Helper function for handling rests in prepared spells
  const handleRestClick = (restType) => {
    if (restType === 'short') {
      // Short rest - restore some spell slots
      const newSlots = {...spellSlots};
      Object.keys(newSlots).forEach(level => {
        if (parseInt(level) <= 5) { // Only restore slots level 5 and below for short rest
          newSlots[level].used = Math.max(0, newSlots[level].used - Math.floor(newSlots[level].max / 2));
        }
      });
      setSpellSlots(newSlots);
      console.log('Short rest taken, some spell slots restored.');
    } else if (restType === 'long') {
      // Long rest - restore all spell slots
      const newSlots = {...spellSlots};
      Object.keys(newSlots).forEach(level => {
        newSlots[level].used = 0;
      });
      setSpellSlots(newSlots);
      console.log('Long rest taken, all spell slots restored.');
    }
  };

  // Function to load favorite spells from the server
  const fetchFavoriteSpells = async () => {
    try {
      console.log('Fetching favorite spells from server');
      const response = await fetch(`${API_URL}/character-data/spell-favorites`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        console.error('Server responded with status:', response.status);
        throw new Error(`Failed to fetch favorite spells: ${response.statusText}`);
      }
      
      const data = await response.json();
      console.log('Favorite spells loaded from user profile:', data?.length || 0, 'spells');
      
      if (Array.isArray(data)) {
        setFavoriteSpells(data);
      } else {
        console.warn('Server returned invalid data format for favorites', data);
        setFavoriteSpells([]);
      }
    } catch (error) {
      console.error('Error fetching favorite spells:', error);
      setFavoriteSpells([]);
    }
  };

  return (
    <div className="adventurer-dashboard">
      {activeTab !== 'spellManager' ? (
        <>
          <div className="dashboard-header">
            <h1>{characterSheet?.name || currentUser?.displayName || 'Adventurer'}</h1>
            <button 
              className="logout-button"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
          
          {/* Horizontal carousel menu */}
          <div className="horizontal-menu-container">
            <div className="horizontal-menu">
              <button 
                className={`carousel-tab ${activeTab === 'overview' ? 'active' : ''}`}
                onClick={() => setActiveTab('overview')}
              >
                Messages
              </button>
              <button 
                className={`carousel-tab ${activeTab === 'character' ? 'active' : ''}`}
                onClick={() => setActiveTab('character')}
              >
                Character
              </button>
              <button 
                className={`carousel-tab ${activeTab === 'gallery' ? 'active' : ''}`}
                onClick={() => setActiveTab('gallery')}
              >
                Gallery
              </button>
              <button 
                className={`carousel-tab ${activeTab === 'account' ? 'active' : ''}`}
                onClick={() => setActiveTab('account')}
              >
                Account
              </button>
              <button 
                className="carousel-tab spells-link"
                onClick={() => setActiveTab('spellManager')}
              >
                L.I.B.R.I.S. Archives
              </button>
            </div>
          </div>
          
          <div className="tab-content">
            {activeTab === 'overview' && renderOverviewTab()}
            {activeTab === 'character' && renderCharacterTab()}
            {activeTab === 'gallery' && renderGalleryTab()}
            {activeTab === 'account' && renderAccountTab()}
          </div>
        </>
      ) : (
        // Completely separate Spells page
        <div className="spells-page">
          <div className="spells-page-header">
            <button 
              className="back-to-profile"
              onClick={() => setActiveTab('overview')}
            >
              ← Back to Profile
            </button>
            <h1>L.I.B.R.I.S. Archives</h1>
          </div>
          
          {/* Spell Manager Content */}
          <div className="spell-manager-content">
            {viewMode === 'library' && (
              <>
                <div className="spell-search-container">
                  <input
                    type="text"
                    className="spell-search-input"
                    placeholder="Search spells..."
                    value={spellSearch}
                    onChange={(e) => setSpellSearch(e.target.value)}
                  />
                  <div className="filter-buttons">
                    <button 
                      className={`filter-button ${activeSpellFilters.favorites ? 'active' : ''}`}
                      onClick={() => setActiveSpellFilters({...activeSpellFilters, favorites: !activeSpellFilters.favorites})}
                    >
                      <span role="img" aria-label="Favorites">⭐</span> Favorites
                    </button>
                    <button 
                      className={`filter-button ${activeSpellFilters.prepared ? 'active' : ''}`}
                      onClick={() => setActiveSpellFilters({...activeSpellFilters, prepared: !activeSpellFilters.prepared})}
                    >
                      Prepared Only
                    </button>
                    <button 
                      className={`filter-button ${activeSpellFilters.level.length > 0 ? 'active' : ''}`}
                      onClick={() => setFilterMenuOpen('level')}
                    >
                      Level
                    </button>
                    <button 
                      className={`filter-button ${activeSpellFilters.school.length > 0 ? 'active' : ''}`}
                      onClick={() => setFilterMenuOpen('school')}
                    >
                      School
                    </button>
                  </div>
                </div>
                {renderSpellLibrary()}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdventurerDashboard; 